import axios from 'axios'

// default path user
const instance = axios.create({
  baseURL: process.env.VUE_APP_DEFAULT_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

// instance.interceptors.request.use((config) => {
// 	if(store.getters.isAuthenticated){
// 		config.headers.common.Authorization = 'Bearer ' + store.getters.token
// 	}
// 	return config;
// });

export default instance
