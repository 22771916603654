import axios from '@/api/axios-users2';

const state = {
    users2: [],
    users2TotalPage: 0,
    users2TotalUser: 0,
    isLoadingUsers2: false,
    allUsers: [],
    usersCountNewUser: [],
    usersCountAge: [],
    usersCountGender: [],
    usersCountRegisterMethod: [],
    usersCountRelationship: [],
    isLoadingUsersCountNewUser: false,
    isLoadingUsersCountAge: false,
    isLoadingUsersCountGender: false,
    isLoadingUsersCountRegisterMethod: false,
    isLoadingUsersCountRelationship: false,
};

const mutations = {
    setUsers2(state, value) {
        state.users2 = value;
    },
    setUsers2TotalPage(state, value) {
        state.users2TotalPage = value;
    },
    setUsers2TotalUser(state, value) {
        state.users2TotalUser = value;
    },
    setAllUsers(state, value) {
        state.allUsers = value;
    },
    setUsersCountNewUser(state, value) {
        state.usersCountNewUser = value;
    },
    setUsersCountAge(state, value) {
        state.usersCountAge = value;
    },
    setUsersCountGender(state, value) {
        state.usersCountGender = value;
    },
    setUsersCountRegisterMethod(state, value) {
        state.usersCountRegisterMethod = value;
    },
    setUsersCountRelationship(state, value) {
        state.usersCountRelationship = value;
    },
    setIsLoadingUsers2(state, value) {
        state.isLoadingUsers2 = value;
    },
    setIsLoadingUsersCountNewUser(state, value) {
        state.isLoadingUsersCountNewUser = value;
    },
    setIsLoadingUsersCountAge(state, value) {
        state.isLoadingUsersCountAge = value;
    },
    setIsLoadingUsersCountGender(state, value) {
        state.isLoadingUsersCountGender = value;
    },
    setIsLoadingUsersCountRegisterMethod(state, value) {
        state.isLoadingUsersCountRegisterMethod = value;
    },
    setIsLoadingUsersCountRelationship(state, value) {
        state.isLoadingUsersCountRelationship = value;
    },
};

const actions = {
    getUsers2: async (
        { commit, getters },
        {
            page = null,
            limit = null,
            id = null,
            name = null,
            phone = null,
            email = null,
        }
    ) => {
        commit('setIsLoadingUsers2', true);
        let params = {
            page: page,
            limit: limit,
            id: id,
            name: name,
            phone: phone,
            email: email,
        };

        await axios
            .get('/users', {
                params: params,
            })
            .then((res) => {
                if (res.data !== null) {
                    commit('setUsers2', res.data.users);
                    commit('setUsers2TotalPage', res.data.meta.pagination.pages);
                    commit('setUsers2TotalUser', res.data.meta.pagination.total);
                } else {
                    commit('setUsers2', []);
                    commit('setUsers2TotalUser', 0);
                }
                commit('setIsLoadingUsers2', false);
            })
            .catch((err) => {
                // alert(err);
                commit('setIsLoadingUsers2', false);
            });
    },
    getAllUsers: async (
        { commit, getters },
        {
            id = null,
            name = null,
            phone = null,
            email = null,
        }
    ) => {
        let params = {
            id: id,
            name: name,
            phone: phone,
            email: email,
        };

        await axios
            .get('/all-users', {
                params: params,
            })
            .then((res) => {
                if (res.data !== null) {
                    commit('setAllUsers', res.data);
                } else {
                    commit('setAllUsers', []);
                }
            })
            .catch((err) => {
                // alert(err);
            });
    },
    getUsersCountNewUser: async (
        { commit, getters },
        {
            start = null,
            end = null,
        }
    ) => {
        commit('setIsLoadingUsersCountNewUser', true);
        let params = {
            start: start,
            end: end,
        };
        await axios
            .get('/users/count/new-user', {
                params: params,
            })
            .then((res) => {
                if (res.data !== null) {
                    commit('setUsersCountNewUser', res.data);
                } else {
                    commit('setUsersCountNewUser', []);
                }
                commit('setIsLoadingUsersCountNewUser', false);
            })
            .catch((err) => {
                // alert(err);
                commit('setIsLoadingUsersCountNewUser', false);
            });
    },
    getUsersCountAge: async (
        { commit, getters },
        {
            start = null,
            end = null,
        }
    ) => {
        commit('setIsLoadingUsersCountAge', true);
        let params = {
            start: start,
            end: end,
        };
        await axios
            .get('/users/count/age', {
                params: params,
            })
            .then((res) => {
                if (res.data !== null) {
                    commit('setUsersCountAge', res.data);
                } else {
                    commit('setUsersCountAge', []);
                }
                commit('setIsLoadingUsersCountAge', false);
            })
            .catch((err) => {
                // alert(err);
                commit('setIsLoadingUsersCountAge', false);
            });
    },
    getUsersCountGender: async (
        { commit, getters },
        {
            start = null,
            end = null,
        }
    ) => {
        commit('setIsLoadingUsersCountGender', true);
        let params = {
            start: start,
            end: end,
        };
        await axios
            .get('/users/count/gender', {
                params: params,
            })
            .then((res) => {
                if (res.data !== null) {
                    commit('setUsersCountGender', res.data);
                } else {
                    commit('setUsersCountGender', []);
                }
                commit('setIsLoadingUsersCountGender', false);
            })
            .catch((err) => {
                // alert(err);
                commit('setIsLoadingUsersCountGender', false);
            });
    },
    getUsersCountRegisterMethod: async (
        { commit, getters },
        {
            start = null,
            end = null,
        }
    ) => {
        commit('setIsLoadingUsersCountRegisterMethod', true);
        let params = {
            start: start,
            end: end,
        };
        await axios
            .get('/users/count/register-method', {
                params: params,
            })
            .then((res) => {
                if (res.data !== null) {
                    commit('setUsersCountRegisterMethod', res.data);
                } else {
                    commit('setUsersCountRegisterMethod', []);
                }
                commit('setIsLoadingUsersCountRegisterMethod', false);
            })
            .catch((err) => {
                // alert(err);
                commit('setIsLoadingUsersCountRegisterMethod', false);
            });
    },
    getUsersCountRelationship: async (
        { commit, getters },
        {
            start = null,
            end = null,
        }
    ) => {
        commit('setIsLoadingUsersCountRelationship', true);
        let params = {
            start: start,
            end: end,
        };
        await axios
            .get('/users/count/relationship', {
                params: params,
            })
            .then((res) => {
                if (res.data !== null) {
                    commit('setUsersCountRelationship', res.data);
                } else {
                    commit('setUsersCountRelationship', []);
                }
                commit('setIsLoadingUsersCountRelationship', false);
            })
            .catch((err) => {
                // alert(err);
                commit('setIsLoadingUsersCountRelationship', false);
            });
    },
};

const getters = {
    users2: (state) => { return state.users2; },
    users2TotalPage: (state) => { return state.users2TotalPage; },
    users2TotalUser: (state) => { return state.users2TotalUser; },
    isLoadingUsers2: (state) => { return state.isLoadingUsers2; },
    allUsers: (state) => { return state.allUsers; },
    usersCountNewUser: (state) => { return state.usersCountNewUser; },
    usersCountAge: (state) => { return state.usersCountAge; },
    usersCountGender: (state) => { return state.usersCountGender; },
    usersCountRegisterMethod: (state) => { return state.usersCountRegisterMethod; },
    usersCountRelationship: (state) => { return state.usersCountRelationship; },
    isLoadingUsersCountNewUser: (state) => { return state.isLoadingUsersCountNewUser; },
    isLoadingUsersCountAge: (state) => { return state.isLoadingUsersCountAge; },
    isLoadingUsersCountGender: (state) => { return state.isLoadingUsersCountGender; },
    isLoadingUsersCountRegisterMethod: (state) => { return state.isLoadingUsersCountRegisterMethod; },
    isLoadingUsersCountRelationship: (state) => { return state.isLoadingUsersCountRelationship; },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
