// parse Zodiac
const zodiac = [
	"",
	"ปีชวด",
	"ปีฉลู",
	"ปีขาล",
	"ปีเถาะ",
	"ปีมะโรง",
	"ปีมะเส็ง",
	"ปีมะเมีย",
	"ปีมะแม",
	"ปีวอก",
	"ปีระกา",
	"ปีจอ",
	"ปีกุน",
];

export default zodiac;
