import axios from 'axios'
import store from '../store'

// default path for fortune-telling
const instance = axios.create({
  // baseURL: process.env.VUE_APP_BASE_URL + '/v2/calendar/admin',
  baseURL: process.env.VUE_APP_CORE_ADMIN_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

instance.interceptors.request.use((config) => {
  if (store.getters.isAuthenticated) {
    // config.headers.common.Authorization = 'Bearer ' + store.getters.token
    config.headers.setAuthorization('Bearer ' + store.getters.token)
  }
  return config
})

export default instance
