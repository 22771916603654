import axios from '@/api/axios-ads';

const state = {
    ads: [],
    adsTotalPage: 0,
    adsIsLoading: false,
};

const mutations = {
    setAds(state, ads) {
        state.ads = ads;
    },
    setAdsTotalPage(state, pages) {
        state.adsTotalPage = pages;
    },
    setAdsIsLoading(state, status) {
        state.adsIsLoading = status;
    },
};

const actions = {
    getAds: async (
        { commit, getters },
        {
            page = 1,
            limit = 15,
            customer_id_name_search = null,
            ad_status = null,
            ad_type = null,
            ad_position = null,
        }
    ) => {
        commit('setAdsIsLoading', true);
        let params = {
            page: page,
            limit: limit,
            customer_id_name_search: customer_id_name_search,
            ad_status: ad_status,
            ad_type: ad_type,
            ad_position: ad_position,
        };
        await axios
            .get('/ads', {
                params: params,
            })
            .then((res) => {
                if (res.data.ads !== null) {
                    commit('setAds', res.data.ads);
                    commit('setAdsTotalPage', res.data.meta.pagination.pages);
                } else {
                    commit('setAds', []);
                }
                commit('setAdsIsLoading', false);
            })
            .catch((err) => {
                // alert(err);
                commit('setAdsIsLoading', false);
            });
    },
    deleteAd: async ({ commit }, data) => {
        await await axios
            .delete('/ads/' + data.id)
            .then()
            .catch((err) => alert(err));
    },
};

const getters = {
    ads: (state) => {
        return state.ads;
    },
    adsTotalPage: (state) => {
        return state.adsTotalPage;
    },
    adsIsLoading: (state) => {
        return state.adsIsLoading;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
