export function downloadCsv(csv, filename) {
    // Once we are done looping, download the .csv by creating a link
    let link = document.getElementById('download-csv');
    if (!link) {
        link = document.createElement('a');
        link.id = 'download-csv';
    }
    // add universalBOM in csv to fix cannot read thai character when open exported csv in excel
    // https://stackoverflow.com/questions/42462764/javascript-export-csv-encoding-utf-8-issue
    var universalBOM = "\uFEFF";
    link.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' + encodeURIComponent(universalBOM + csv)
    );
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    document.querySelector('#download-csv').click();
}
export function objectsToCsv(data, filterHeaders) {
    const csvRows = [];

    /* Get headers as every csv data format
    has header (head means column name)
    so objects key is nothing but column name
    for csv data using Object.key() function.
    We fetch key of object as column name for
    csv */
    let headers = [];
    if (filterHeaders) {
        headers = filterHeaders;
    } else {
        headers = Object.keys(data[0]);
    }

    /* Using push() method we push fetched
       data into csvRows[] array */
    csvRows.push(headers.join(','));

    // Loop to get value of each objects key
    for (const row of data) {
        const values = headers.map((header) => {
            const val = row[header];
            return `"${val}"`;
        });

        // To add, separator between each value
        csvRows.push(values.join(','));
    }

    /* To add new line for each objects values
       and this return statement array csvRows
       to this function.*/
    return csvRows.join('\n');
}
