import axios from '@/api/axios-select-options';

const state = {
    adStatuses: [],
    adTypes: [],
    adPositions: [],
    adRewardPositions: [],
    adOrders: [],
    auspiciousTypes: [],
};

const mutations = {
    setAdStatuses(state, adStatuses) {
        state.adStatuses = adStatuses;
    },
    setAdTypes(state, adTypes) {
        state.adTypes = adTypes;
    },
    setAdPositions(state, adPositions) {
        state.adPositions = adPositions;
    },
    setAdRewardPositions(state, adRewardPositions) {
        state.adRewardPositions = adRewardPositions;
    },
    setAdOrders(state, adOrders) {
        state.adOrders = adOrders;
    },
    setAuspiciousTypes(state, auspiciousTypes) {
        state.auspiciousTypes = auspiciousTypes;
    },
};
const actions = {
    getSelectOptionsAdStatus: async ({ commit, getters }) => {
        await axios
            .get('/ad-status')
            .then((res) => {
                if (res.data !== null) commit('setAdStatuses', res.data);
                else commit('setAdStatuses', []);
            })
            .catch((err) => {
                // alert(err);
            });
    },
    getSelectOptionsAdType: async ({ commit, getters }) => {
        await axios
            .get('/ad-type')
            .then((res) => {
                if (res.data !== null) commit('setAdTypes', res.data);
                else commit('setAdTypes', []);
            })
            .catch((err) => {
                // alert(err);
            });
    },
    getSelectOptionsAdPosition: async ({ commit, getters }) => {
        await axios
            .get('/ad-position')
            .then((res) => {
                if (res.data !== null) commit('setAdPositions', res.data);
                else commit('setAdPositions', []);
            })
            .catch((err) => {
                // alert(err);
            });
    },
    getSelectOptionsAdRewardPosition: async ({ commit, getters }) => {
        await axios
            .get('/ad-reward-position')
            .then((res) => {
                if (res.data !== null) commit('setAdRewardPositions', res.data);
                else commit('setAdRewardPositions', []);
            })
            .catch((err) => {
                // alert(err);
            });
    },
    getSelectOptionsAdOrder: async ({ commit, getters }) => {
        await axios
            .get('/ad-order')
            .then((res) => {
                if (res.data !== null) commit('setAdOrders', res.data);
                else commit('setAdOrders', []);
            })
            .catch((err) => {
                // alert(err);
            });
    },
    getSelectOptionsAuspiciousType: async ({ commit, getters }) => {
        await axios
            .get('/auspicious-type')
            .then((res) => {
                if (res.data !== null) commit('setAuspiciousTypes', res.data);
                else commit('setAuspiciousTypes', []);
            })
            .catch((err) => {
                // alert(err);
            });
    },
};

const getters = {
    adStatuses(state) {
        return state.adStatuses;
    },
    adTypes(state) {
        return state.adTypes;
    },
    adPositions(state) {
        return state.adPositions;
    },
    adRewardPositions(state) {
        return state.adRewardPositions;
    },
    adOrders(state) {
        return state.adOrders;
    },
    auspiciousTypes(state) {
        return state.auspiciousTypes;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
