import axios from "@/api/axios-stock";

const state = {
  stocks: [],
};

const mutations = {
  setStocks(state, stocks) {
    state.stocks = stocks;
  },
};

const labels = {
  "14_day": "Premium : 14 days",
  "1_month_for_invitee": "Premium : 1 month for invitee",
  "1_month": "Premium : 1 month",
  "2_month": "Premium : 2 months",
  "3_month": "Premium : 3 months",
  "1_profile": "Compatibility : 1 Profile",
  "3_profile": "Compatibility : 3 Profiles",
};

const actions = {
  getStocks: async () => {
    return state.stocks;
  },
  loadStocks: async ({ commit }) => {
    let response = await axios.get("/reward-codes/usage");
    let items = response.data.rewards;
    let transformedItems = [];

    // Initialize transformedItems with default values and labels
    Object.keys(labels).forEach((type) => {
      transformedItems.push({
        type: type,
        android_usage: 0,
        ios_usage: 0,
        label: labels[type], // Add the label field
      });
    });

    items.forEach((item) => {
      let existingItem = transformedItems.find((i) => i.type === item.type);
      if (existingItem) {
        if (item.os === "android") {
          existingItem.android_usage = item.number_of_usage;
        } else if (item.os === "ios") {
          existingItem.ios_usage = item.number_of_usage;
        }
      }
    });

    // Sort the transformedItems based on the order of keys in the labels object
    const labelOrder = Object.keys(labels);
    transformedItems.sort((a, b) => {
      return labelOrder.indexOf(a.type) - labelOrder.indexOf(b.type);
    });

    commit("setStocks", transformedItems);
  },
};

const getters = {
  stocks: (state) => state.stocks,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
