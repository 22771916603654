// ghost_festival_year_ch
export const ghostFestival = [
	{ text: "ไม่มี", ch: "", code: 0 },
	{
		text: "1 立春 Coming of Spring จุดเริ่มต้นของฤดูใบไม้ผลิ",
		ch: "立春",
		code: 1,
	},
	{ text: "2 雨水 Rain Water ฤดูฝน", ch: "雨水", code: 2 },
	{
		text: "3 驚蟄 Awakening of Worms ฤดูการตื่นขึ้นของแมลง",
		ch: "驚蟄",
		code: 3,
	},
	{ text: "4 春分 Spring Equinox กลางฤดูใบไม้ผลิ", ch: "春分", code: 4 },
	{
		text: "5 清明 Clear and Bright ท้องฟ้าโปร่งและแจ่มใส",
		ch: "清明",
		code: 5,
	},
	{ text: "6 穀雨 Grain Rain เม็ดฝน", ch: "穀雨", code: 6 },
	{
		text: "7 立夏 Coming of Summer จุดเริ่มต้นของฤดูร้อน",
		ch: "立夏",
		code: 7,
	},
	{ text: "8 小滿 Small Sprout พืชพรรณออกดอก", ch: "小滿", code: 8 },
	{
		text: "9 芒種 Planting of Thorny Crops พืชพรรณออกผล",
		ch: "芒種",
		code: 9,
	},
	{ text: "10 夏至 Summer Solstice	กลางฤดูร้อน", ch: "夏至", code: 10 },
	{ text: "11 小暑 Lesser Heat	อากาศร้อนพอควร", ch: "小暑", code: 11 },
	{ text: "12 大暑 Greater Heat อากาศร้อนอบอ้าว", ch: "大暑", code: 12 },
	{
		text: "13 立秋 Coming Autumn จุดเริ่มต้นของฤดูใบไม้ร่วง",
		ch: "立秋",
		code: 13,
	},
	{ text: "14 處暑 Heat Ends สุดฤดูร้อน", ch: "處暑", code: 14 },
	{ text: "15 白露 White Dew น้ำค้าง", ch: "白露", code: 15 },
	{ text: "16 秋分 Autumn Equinox กลางฤดูใบไม้ร่วง", ch: "秋分", code: 16 },
	{
		text: "17 寒露 Cold dew น้ำค้างเริ่มเป็นเกล็ดปนน้ำ",
		ch: "寒露",
		code: 17,
	},
	{ text: "18 霜降 Frosting น้ำค้างเริ่มแข็งจัด", ch: "霜降", code: 18 },
	{
		text: "19 立冬 Coming of Winter จุดเริ่มต้นของฤดูหนาว",
		ch: "立冬",
		code: 19,
	},
	{ text: "20 小雪 Lesser Snow	หิมะตกพอควร", ch: "小雪", code: 20 },
	{ text: "21 大雪 Greater Snow หิมะตกหนัก", ch: "大雪", code: 21 },
	{ text: "22 冬至 Winter Solstice	กลางฤดูหนาว", ch: "冬至", code: 22 },
	{ text: "23 小雪 Lesser Cold	อากาศหนาวพอควร", ch: "小雪", code: 23 },
	{ text: "24 大寒 Greater Cold อากาศหนาวจัด", ch: "大寒", code: 24 },
];

// use to detect text
export const ghostFestivalCh = [
	"立春",
	"雨水",
	"驚蟄",
	"春分",
	"清明",
	"穀雨",
	"立夏",
	"小滿",
	"芒種",
	"夏至",
	"小暑",
	"大暑",
	"立秋",
	"處暑",
	"白露",
	"秋分",
	"寒露",
	"霜降",
	"立冬",
	"小雪",
	"大雪",
	"冬至",
	"小雪",
	"大寒",
];

// day_bazi_pattern
export const dayBaziPattern = [
	{ text: "1 甲子	Jia Zi กะจื้อ วันชวดธาตุทองในทะเล", code: 1 },
	{ text: "2 乙丑 Yi Chou อิกทิ่ว วันฉลูธาตุทองในทะเล", code: 2 },
	{ text: "3 丙寅 Bing Yin เปี้ยอิ๊ง วันขาลธาตุไฟในเตา", code: 3 },
	{ text: "4 丁卯 Ding Mao เต็งเบ้า วันเถาะธาตุไฟในเตา", code: 4 },
	{ text: "5 戊辰 Wu Chen โบ่วซิ้ง วันมะโรงธาตุไม้ในป่า", code: 5 },
	{ text: "6 己巳 Ji Si กี้จี๋ วันมะเส็งในป่า", code: 6 },
	{ text: "7 庚午 Geng Wu แกโง่ว วันมะเมียธาตุดินข้างถนน", code: 7 },
	{ text: "8 辛未 Xin Wei เซ็งบี่ วันมะแมธาตุดินข้างถนน", code: 8 },
	{ text: "9 壬申 Ren Shen หยิ่มซิง วันวอกธาตุทองบนปลายดาบ", code: 9 },
	{ text: "10 癸酉 Gui You กุยอิ้ว วันระกาธาตุทองบนปลายดาบ", code: 10 },
	{ text: "11 甲戍 Jia Xu กะสุก วันจอธาตุไฟบนภูเขา", code: 11 },
	{ text: "12 乙亥 Yi Hai อิกไห วันกุนธาตุไฟบนภูเขา", code: 12 },
	{ text: "13 丙子 Bing Zi เปี้ยจื้อ วันชวดธาตุน้ำในลำคลอง", code: 13 },
	{ text: "14 丁丑 Ding Chou เต็งทิ่ว วันฉลูธาตุน้ำในลำคลอง", code: 14 },
	{ text: "15 戊寅 Wu Yin โบ่วอิ๊ง วันขาลธาตุดินบนกำแพง", code: 15 },
	{ text: "16 己卯 Ji Mao กี้เบ้า วันเถาะธาตุดินบนกำแพง", code: 16 },
	{ text: "17 庚辰 Geng Chen แกซิ้ง วันมะโรงธาตุทองในขี้ผึ้งขาว", code: 17 },
	{ text: "18 辛巳 Xin Si เซ็งจี๋ วันมะเส็งธาตุทองในขี้ผึ้งขาว", code: 18 },
	{ text: "19 壬午 Ren Wu หยิ่มโง่ว วันมะเมียธาตุไม้ต้นสน", code: 19 },
	{ text: "20 癸未 Gui Wei กุยบี่ วันมะแมธาตุไม้ต้นสน", code: 20 },
	{ text: "21 甲申 Jia Shen กะซิง วันวอกธาตุน้ำในลำธาร", code: 21 },
	{ text: "22 乙酉 Yi You อิ่กอิ้ว วันระกาธาตุน้ำในลำธาร", code: 22 },
	{ text: "23 丙戌 Bing Xu เปี้ยสุก วันจอธาตุดินบนบ้าน", code: 23 },
	{ text: "24 丁亥 Ding Hai เต็งไห วันกุนธาตุดินบนบ้าน", code: 24 },
	{ text: "25 戊子 Wu Zi โบ่วจื้อ วันชวดธาตุไฟฟ้าหลม", code: 25 },
	{ text: "26 己丑 Ji Chou กี้ทิ่ว วันฉลูธาตุไฟฟ้าหลม", code: 26 },
	{ text: "27 庚寅 Geng Yin แกอิ๊ง วันขาลธาตุไม้ต้นสน", code: 27 },
	{ text: "28 辛卯 Xin Mao เซ็งเบ้า วันเถาะธาตุไม้ต้นสน", code: 28 },
	{ text: "29 壬辰 Ren Chen หยิ่มซิ้ง วันมะโรงธาตุน้ำไหลไม่ขาด", code: 29 },
	{ text: "30 癸巳 Gui Si กุยจี๋ วันมะเส็งธาตุน้ำไหลไม่ขาด", code: 30 },
	{ text: "31 甲午 Jia Wu กะโง่ว วันมะเมียธาตุทองในทะเล", code: 31 },
	{ text: "32 乙未 Yi Wei อิกบี่ วันมะแมธาตุทองในทะเล", code: 32 },
	{ text: "33 丙申 Bing Shen เปี้ยซิง วันวอกธาตุไฟใต้ภูเขา", code: 33 },
	{ text: "34 丁酉 Ding You เต็งอิ้ว วันระกาธาตุไฟใต้ภูเขา", code: 34 },
	{ text: "35 戊戌 Wu Xu โบ่วสุก วันจอธาตุไม้ในที่ราบ", code: 35 },
	{ text: "36 己亥 Ji Hai กี้ไห วันกุนธาตุไม้ในที่ราบ", code: 36 },
	{ text: "37 庚子 Geng Zi แกจื้อ วันชวดธาตุดินบนผนัง", code: 37 },
	{ text: "38 辛丑 Xin Chou เซ็งทิ่ว วันฉลูธาตุดินบนผนัง", code: 38 },
	{ text: "39 壬寅 Ren Yin หยิมอิ๊ง วันขาลธาตุทองของทองแผ่น", code: 39 },
	{ text: "40 癸卯 Gui Mao กุยเบ้า วันเถาะธาตุทองของทองแผ่น", code: 40 },
	{ text: "41 甲辰 Jia Chen กะซิ้ง วันมะโรงธาตุไฟในตะเกียง", code: 41 },
	{ text: "42 乙巳 Yi Si อิ๊กจี๋ วันมะเส็งธาตุไฟในตะเกียง", code: 42 },
	{
		text: "43 丙午 Bing Wu เปี้ยโง่ว วันมะเมียธาตุน้ำของน้ำบนท้องฟ้า",
		code: 43,
	},
	{ text: "44 丁未 Ding Wei เต็งบี่ วันมะแมธาตุน้ำของน้ำบนทองฟ้า", code: 44 },
	{ text: "45 戊申 Wu Shen โบ่วซิง วันวอกธาตุดินในถ้ำ", code: 45 },
	{ text: "46 己酉 Ji You กี้อิ้ว วันระกาธาตุดินในถ้ำ", code: 46 },
	{ text: "47 庚戌 Geng Xu แกสุก วันจอธาตุทองปิ่นปักผม", code: 47 },
	{ text: "48 辛亥 Xin Hai ซิงไห วันกุนธาตุทองปิ่นปักผม", code: 48 },
	{ text: "49 壬子 Ren Zi หยิ่มจื้อ วันชวดธาตุไม้ต้นทับทิม", code: 49 },
	{ text: "50 癸丑 Gui Chou กุ่ยทิ่ว วันฉลูธาตุไม้ต้นทับทิม", code: 50 },
	{ text: "51 甲寅 Jia Yin กะอิ๊ง วันขาลธาตุน้ำในลำคลอง", code: 51 },
	{ text: "52 乙卯 Yi Mao อิกเบ้า วันเถาะธาตุน้ำในลำคลอง", code: 52 },
	{ text: "53 丙辰 Bing Chen เปี้ยซิ้ง วันมะโรงธาตุดินในทราย", code: 53 },
	{ text: "54 丁巳 Ding Si เต็งจี๋ วันเส็งธาตุดินในทราย", code: 54 },
	{ text: "55 戊午 Wu Wu โบ่วโง่ว วันมะเมียธาตุไฟบนท้องฟ้า", code: 55 },
	{ text: "56 己未 Ji Wei กี้บี่ วันมะแมธาตุไฟบนท้องฟ้า", code: 56 },
	{ text: "57 庚申 Geng Shen แกซิง วันวอกธาตุไม้ต้นทับทิม", code: 57 },
	{ text: "58 辛酉 Xin You เซ็งอิ้ว วันระกาธาตุไม้ต้นทับทิม", code: 58 },
	{ text: "59 壬戌 Ren Xu หยิ่มสุก วันจอธาตุน้ำทะเล", code: 59 },
	{ text: "60 癸亥 Gui Hai กุยไห วันกุนธาตุน้ำทะเล", code: 60 },
];

// element_year
export const elementYear = [
	{ text: "Yin Wood", code: 1 },
	{ text: "Yin Fire", code: 2 },
	{ text: "Yin Metal", code: 3 },
	{ text: "Yin Water", code: 4 },
	{ text: "Yin Earth", code: 5 },
	{ text: "Yang Wood", code: 6 },
	{ text: "Yang Fire", code: 7 },
	{ text: "Yang Metal", code: 8 },
	{ text: "Yang Water", code: 9 },
	{ text: "Yang Earth", code: 10 },
];

// day_constellation
export const dayConstellation = [
	{ text: "1 角 Horn เขาสัตว์", code: 1 },
	{ text: "2 亢 Neck คอ", code: 2 },
	{ text: "3 氐 Foundation รากไม้", code: 3 },
	{ text: "4 房 House บ้าน", code: 4 },
	{ text: "5 心 Heart หัวใจ", code: 5 },
	{ text: "6 尾 Tail หางสัตว์", code: 6 },
	{ text: "7 箕 Basket ตะกร้า", code: 7 },
	{ text: "8 斗 Dipper กระบวย", code: 8 },
	{ text: "9 牛 Ox วัว", code: 9 },
	{ text: "10 女 Weaving Maiden เด็กหญิง", code: 10 },
	{ text: "11 虛 Void ความว่างเปล่า", code: 11 },
	{ text: "12 危 Danger อันตราย", code: 12 },
	{ text: "13 室 Room ห้อง", code: 13 },
	{ text: "14 壁 Wall กำแพง", code: 14 },
	{ text: "15 奎 Astride แข้ง", code: 15 },
	{ text: "16 婁 Mound ชายเสื้อ", code: 16 },
	{ text: "17 胃 Stomach กระเพาะ", code: 17 },
	{ text: "18 昂 Pleiades ผมหยิก", code: 18 },
	{ text: "19 畢 Net ตาข่ายน้อย", code: 19 },
	{ text: "20 觜 Beak หัวเต่า", code: 20 },
	{ text: "21 參 Orion โสม", code: 21 },
	{ text: "22 井 Well บ่อน้ำ", code: 22 },
	{ text: "23 鬼 Ghost ผี", code: 23 },
	{ text: "24 柳 Willow ต้นหลิว", code: 24 },
	{ text: "25 星 Star ดาวโดด", code: 25 },
	{ text: "26 張 Bow ตาข่ายใหญ่", code: 26 },
	{ text: "27 翌 Wing ปีกนก", code: 27 },
	{ text: "28 軫 Carriage สารถี", code: 28 },
];

// day_officer
export const dayOfficer = [
	{ text: "1  建 Establish Day วันแห่งการสร้าง", code: 1 },
	{ text: "2  除 Remove Day วันแห่งการขจัด", code: 2 },
	{ text: "3  滿 Full Day วันแห่งความอุดมสมบูรณ์", code: 3 },
	{ text: "4  平 Balance Day วันแห่งความสมดุล", code: 4 },
	{ text: "5  定 Stable Day วันแห่งความเสถียร", code: 5 },
	{ text: "6  執 Initiate Day วันแห่งการริเริ่ม", code: 6 },
	{ text: "7  破 Destruction Day วันแห่งการทำลาย", code: 7 },
	{ text: "8  危 Danger Day วันแห่งความอันตราย", code: 8 },
	{ text: "9  成 Success Day วันแห่งความสำเร็จ", code: 9 },
	{ text: "10 收 Receive Day วันแห่งการได้รับ", code: 10 },
	{ text: "11 開 Open Day วันแห่งการเปิด", code: 11 },
	{ text: "12 閉 Close Day วันแห่งการปิด", code: 12 },
];

// day_ch วันจีน
export const dayCh = [
	{ text: "1  初一日", ch: "初一日", day: 1 },
	{ text: "2  初二日", ch: "初二日", day: 2 },
	{ text: "3  初三日", ch: "初三日", day: 3 },
	{ text: "4  初四日", ch: "初四日", day: 4 },
	{ text: "5  初五日", ch: "初五日", day: 5 },
	{ text: "6  初六日", ch: "初六日", day: 6 },
	{ text: "7  初七日", ch: "初七日", day: 7 },
	{ text: "8  初八日", ch: "初八日", day: 8 },
	{ text: "9  初九日", ch: "初九日", day: 9 },
	{ text: "10 初十日", ch: "初十日", day: 10 },
	{ text: "11 十一日", ch: "十一日", day: 11 },
	{ text: "12 十二日", ch: "十二日", day: 12 },
	{ text: "13 十三日", ch: "十三日", day: 13 },
	{ text: "14 十四日", ch: "十四日", day: 14 },
	{ text: "15 十五日", ch: "十五日", day: 15 },
	{ text: "16 十六日", ch: "十六日", day: 16 },
	{ text: "17 十七日", ch: "十七日", day: 17 },
	{ text: "18 十八日", ch: "十八日", day: 18 },
	{ text: "19 十九日", ch: "十九日", day: 19 },
	{ text: "20 二十日", ch: "二十日", day: 20 },
	{ text: "21 廿一日", ch: "廿一日", day: 21 },
	{ text: "22 廿二日", ch: "廿二日", day: 22 },
	{ text: "23 廿三日", ch: "廿三日", day: 23 },
	{ text: "24 廿四日", ch: "廿四日", day: 24 },
	{ text: "25 廿五日", ch: "廿五日", day: 25 },
	{ text: "26 廿六日", ch: "廿六日", day: 26 },
	{ text: "27 廿七日", ch: "廿七日", day: 27 },
	{ text: "28 廿八日", ch: "廿八日", day: 28 },
	{ text: "29 廿九日", ch: "廿九日", day: 29 },
	{ text: "30 三十日", ch: "三十日", day: 30 },
];

// mont_ch เดือนจีน
export const monthCh = [
	{ text: "1 正月", ch: "正月", month: 1 },
	{ text: "2 二月", ch: "二月", month: 2 },
	{ text: "3 三月", ch: "三月", month: 3 },
	{ text: "4 四月", ch: "四月", month: 4 },
	{ text: "5 五月", ch: "五月", month: 5 },
	{ text: "6 六月", ch: "六月", month: 6 },
	{ text: "7 七月", ch: "七月", month: 7 },
	{ text: "8 八月", ch: "八月", month: 8 },
	{ text: "9 九月", ch: "九月", month: 9 },
	{ text: "10 十月", ch: "十月", month: 10 },
	{ text: "11 十一月", ch: "十一月", month: 11 },
	{ text: "12 十二月", ch: "十二月", month: 12 },
	{ text: "13 闰月", ch: "闰月", month: 13 },
];

// zodiac_year ปีนักษัตร
export const zodiac = [
	{ text: "ปีชวด", code: 1 },
	{ text: "ปีฉลู", code: 2 },
	{ text: "ปีขาล", code: 3 },
	{ text: "ปีเถาะ", code: 4 },
	{ text: "ปีมะโรง", code: 5 },
	{ text: "ปีมะเส็ง", code: 6 },
	{ text: "ปีมะเมีย", code: 17 },
	{ text: "ปีมะแม", code: 18 },
	{ text: "ปีวอก", code: 19 },
	{ text: "ปีระกา", code: 10 },
	{ text: "ปีจอ", code: 11 },
	{ text: "ปีกุน", code: 12 },
];

// zodiac_compatibility_ch ปีที่ไม่ถูกกัน
export const zodiacCompatibility = [
	{ text: "1 鼠 冲 馬 ชวด ไม่ถูกกับ มะเมีย", ch: "鼠 冲 馬", code: 1 },
	{ text: "2 牛 冲 羊 ฉลู ไม่ถูกกับ มะแม", ch: "牛 冲 羊", code: 2 },
	{ text: "3 虎 冲 猴 ขาล ไม่ถูกกับ วอก", ch: "虎 冲 猴", code: 3 },
	{ text: "4 兔 冲 鷄 เถาะ ไม่ถูกกับ ระกา", ch: "兔 冲 鷄", code: 4 },
	{ text: "5 龍 冲 狗 มะโรง ไม่ถูกกับ จอ", ch: "龍 冲 狗", code: 5 },
	{ text: "6 蛇 冲 猪 มะเส็ง ไม่ถูกกับ กุน", ch: "蛇 冲 猪", code: 6 },
	{ text: "7 馬 冲 鼠 มะเมีย ไม่ถูกกับ ชวด", ch: "馬 冲 鼠", code: 7 },
	{ text: "8 羊 冲 牛 มะแม ไม่ถูกกับ ฉลู", ch: "羊 冲 牛", code: 8 },
	{ text: "9 猴 冲 虎 วอก ไม่ถูกกับ ขาล", ch: "猴 冲 虎 ", code: 9 },
	{ text: "10 鷄 冲 兔 ระกา ไม่ถูกกับ เถาะ", ch: "鷄 冲 兔", code: 10 },
	{ text: "11 狗 冲 龍 จอ ไม่ถูกกับ มะโรง", ch: "狗 冲 龍", code: 11 },
	{ text: "12 猪 冲 蛇 กุน ไม่ถูกกับ มะเส็ง", ch: "猪 冲 蛇", code: 12 },
];

// ระดับของสิ่งที่ควรทำ
export const rateArray = [
	{ text: "ไม่มี", value: 0 },
	{ text: "ควรทำ", value: 1 },
	{ text: "ควรทำมาก", value: 2 },
	{ text: "ควรทำอย่างมาก", value: 3 },
	{ text: "ไม่ควรทำ", value: 4 },
	{ text: "ไม่ควรทำมาก", value: 5 },
	{ text: "ไม่ควรทำอย่างมาก", value: 6 },
];

// god_of_wealth_direction_ch ทิศเทพเจ้ามั่งคั่ง
export const godOfWealth = [
	{ text: "正北 North", ch: "正北", eng: "North" },
	{ text: "正南 South", ch: "正南", eng: "South" },
	{ text: "東南 Southeast", ch: "東南", eng: "Southeast" },
	{ text: "正東 East", ch: "正東", eng: "East" },
	{ text: "正西 West", ch: "正西", eng: "West" },
];
// use to detect above
export const godOfWealthCh = ["North", "South", "Southeast", "East", "West"];

// god_of_happiness_direction_ch ทิศเทพเจ้ามงคล
export const godOfHappiness = [
	{ text: "東北 Northeast", ch: "東北", eng: "Northeast" },
	{ text: "西北 Northwest", ch: "西北", eng: "Northwest" },
	{ text: "正南 South", ch: "正南", eng: "South" },
	{ text: "西南 Southwest", ch: "西南", eng: "Southwest" },
	{ text: "東南 Southeast", ch: "東南", eng: "Southeast" },
];
// use to detect above
export const godOfHappinessCh = [
	"Northeast",
	"Northwest",
	"South",
	"Southwest",
	"Southeast",
];

// hr_xx ใช้ parse formInput
export const hourParse = [
	{ text: "กาลกิณี", code: 0 },
	{ text: "มงคล", code: 1 },
	{ text: "ปกติ", code: 2 },
];

// moon_phase_image
export const moonImage = [1, 2, 3, 4];

// use to parse prior_xxx
export const phase = ["ขึ้น", "แรม"];

// month_th
export const month = [1, 2, 3, 4, 5, 6, 7, 8, "8-8", 9, 10, 11, 12];
// day_th
export const day = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
// hour_code
export const hourCode = [1, 2, 3, 4, 5];

// formInput bazi
export const elementCh = [
	"甲",
	"乙",
	"丙",
	"丁",
	"戊",
	"己",
	"庚",
	"辛",
	"壬",
	"癸",
];

// formInput bazi
export const animalCh = [
	"子",
	"丑",
	"寅",
	"卯",
	"辰",
	"巳",
	"午",
	"未",
	"申",
	"酉",
	"戌",
	"亥",
];

// display_time / 0 = '-' ไม่ใส่เวลาใน mobile
export const displayTime = [0, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23];
