import axios from "axios";
// import store from "../store";

// default path for login
const instance = axios.create({
        baseURL: process.env.VUE_APP_BASE_URL + '/admin/v1/ad',
        headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
        }
})

// instance.interceptors.request.use((config) => {
// 	if(store.getters.isAuthenticated){
// 		config.headers.common.Authorization = 'Bearer ' + store.getters.token
// 	}
// 	return config;
// });

export default instance;
