import axios from '@/api/axios-users';

const state = {
	users: [],
	subscriptions: [],
	totalUserPage: 1,
	currentUserPage: 1,
	totalUser: 0,
	userPageSize: 25,
	isLoadingUsers: false
};

const mutations = {
	setUsers(state, users) {
		state.users = users;
	},
	getUser(state, user) {
		state.user = user;
	},
	getUserSubscriptions(state, data) {
		state.subscriptions = data;
	},
	addUser(state, data) {
		state.users.push(data);
	},
	updateUser(state, data) {
		state.users[data.index];
	},
	deleteUser(state, index) {
		state.users.splice(index, 1);
	},
	setPage(state, page) {
		state.currentUserPage = page;
	},
	setTotalUser(state, totalUser) {
		state.totalUser = totalUser;
	},
	setTotalPage(state, page) {
		state.totalUserPage = page;
	},
	setIsLoadingUsers(state, status) {
		state.isLoadingUsers = status
	},
};

const actions = {
	setPage: async ({ commit }, pageNumber) => {
		commit('setPage', pageNumber);
	},
	getUsers: async ({ commit, getters }, { page = 1, limit = 15, searchId = null, searchString = null, isSearching = false }) => {
		commit('setIsLoadingUsers', true)
		let params = {
			'page-number': page,
			'page-size': limit,
		}
		if (isSearching) {
			params['page-number'] = 1
			commit('setPage', 1)
		}
		if (searchId) params.id = searchId
		if (searchString) params.search = searchString
		await axios
			.get('/users',
				{
					params: params
				}
			)
			.then((res) => {
				if (res.data.users !== null) {
					commit('setUsers', res.data.users);
					commit('setTotalPage', res.data.total_pages);
					commit('setTotalUser', res.data.total_items);
				}
				else {
					commit('setUsers', []);
				}

				commit('setIsLoadingUsers', false)
			})
			.catch((err) => {
				commit('setIsLoadingUsers', false)
				// alert(err);
			});
	},
	getUser: async ({ commit }, id) => {
		await axios
			.get('/users/' + id)
			.then((res) => {
				commit('getUser', res.data);
			})
			.catch((err) => {
				// alert(err);
			});
	},
	getUserSubscriptions: async ({ commit }, payload) => {
		await axios
			.get('/users/' + payload.id + '/subscriptions')
			.then((res) => {
				commit('getUserSubscriptions', res.data);
			})
			.catch((err) => {
				// alert(err);
			});
	},
	addUser: async ({ commit }, data) => {
		await axios
			.post('/users', data)
			.then(() => commit('addUser', data))
			.catch((err) => alert(err));
	},
	updateUser: async ({ commit }, data) => {
		await axios
			.put('/users/' + data.user_id, data)
			.then(() => commit('updateUser', data))
			.catch((err) => alert(err));
	},
	deactivateUser: async ({ commit }, data) => {
		await axios
			.post('/users/' + data.user_id + '/deactivate')
			.catch((err) => alert(err));
	},
	activateUser: async ({ commit }, data) => {
		await axios
			.post('/users/' + data.user_id + '/activate')
			.catch((err) => alert(err));
	},
	deleteUserAccountAndProfile: async ({ commit }, data) => {
		await axios
			.delete('/users/' + data.user_id + '/profile')
			.then(() => commit('deleteUser'))
			.catch((err) => alert(err));
	},
	deleteUserAccountButData: async ({ commit }, data) => {
		await axios
			.delete('/users/' + data.user_id + '/keep-data')
			.then(() => commit('deleteUser'))
			.catch((err) => alert(err));
	},
	deleteUserAccountAndData: async ({ commit }, data) => {
		await axios
			.delete('/users/' + data.user_id + '/all-data')
			.then(() => commit('deleteUser'))
			.catch((err) => alert(err));
	},
};

const getters = {
	users: (state) => {
		return state.users;
	},
	subscriptions: (state) => {
		return state.subscriptions;
	},
	currentPage: (state) => {
		return state.currentUserPage
	},
	totalUserPage: (state) => {
		return state.totalUserPage
	},
	totalUser: (state) => {
		return state.totalUser
	},
	isLoadingUsers: (state) => {
		return state.isLoadingUsers;
	},
};

export default {
	state,
	mutations,
	actions,
	getters,
};