<template>
  <v-app>
    <Navigation
      v-if="currentRouteName !== 'signin'"
      :navTitle="currentRouteName"
    />
    <v-main>
      <v-container fluid style="height: 100vh">
        <alert />
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Alert from '@/components/alert.vue'
import Navigation from '@/components/navigation.vue'

export default {
  name: 'App',
  components: {
    Navigation,
    Alert,
  },
  async created() {
    if (
      this.$store.getters.events.length === 0 &&
      !this.$store.getters.isLoadingCalendar
    ) {
      // fetch calendar
      // await this.$store.dispatch('getCalendar');
    }
    this.$store.dispatch('initializeFormList')
  },

  computed: {
    currentRouteName() {
      let routeName = this.$route.name
      let routeAliasName = this.$route.meta.aliasName
      if (routeAliasName) {
        return routeAliasName
      } else {
        return routeName
      }
    },
  },
}
</script>
