import axios from '@/api/axios-checkboxes';

const state = {
    months: [],
    countries: [],
    countriesThailandProvinces: [],
};

const mutations = {
    setMonths(state, months) {
        state.months = months;
    },
    setCountries(state, countries) {
        state.countries = countries;
    },
    setCountriesThailandProvinces(state, countriesThailandProvinces) {
        state.countriesThailandProvinces = countriesThailandProvinces;
    },
};
const actions = {
    getCheckboxesMonths: async ({ commit, getters }) => {
        await axios
            .get('/months')
            .then((res) => {
                if (res.data !== null) commit('setMonths', res.data);
                else commit('setMonths', []);
            })
            .catch((err) => {
                // alert(err);
            });
    },
    getCheckboxesCountries: async ({ commit, getters }) => {
        await axios
            .get('/countries')
            .then((res) => {
                if (res.data !== null) commit('setCountries', res.data);
                else commit('setCountries', []);
            })
            .catch((err) => {
                // alert(err);
            });
    },
    getCheckboxesCountriesThailandProvinces: async ({ commit, getters }) => {
        await axios
            .get('/countries/Thailand/provinces')
            .then((res) => {
                if (res.data !== null)
                    commit('setCountriesThailandProvinces', res.data.provinces);
                else commit('setCountriesThailandProvinces', []);
            })
            .catch((err) => {
                // alert(err);
            });
    },
};

const getters = {
    months(state) {
        return state.months;
    },
    countries(state) {
        return state.countries;
    },
    countriesThailandProvinces(state) {
        return state.countriesThailandProvinces;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
