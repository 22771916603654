import axios from '@/api/axios-ads-reports';

const state = {
    adsDashboard: {},
    adsDashboardIsLoading: false,
};

const mutations = {
    setAdsDashboard(state, adsDashboard) {
        state.adsDashboard = adsDashboard;
    },
    setAdsDashboardIsLoading(state, status) {
        state.adsDashboardIsLoading = status;
    },
};

const actions = {
    getAdsDashboard: async (
        { commit, getters },
        {
            start_date = null,
            end_date = null,
            customer_name_search = null,
            ad_type = null,
            ad_position = null,
            auspicious_type = null,
            view = null,
            ad_id = null,
        }
    ) => {
        commit('setAdsDashboardIsLoading', true);

        let params = {
            start_date: start_date,
            end_date: end_date,
            customer_name_search: customer_name_search,
            ad_type: ad_type,
            ad_position: ad_position,
            auspicious_type: auspicious_type,
            view: view,
            ad_id: ad_id,
        };
        await axios
            .get('/dashboard', {
                params: params,
            })
            .then((res) => {
                if (res.data !== null) {
                    commit('setAdsDashboard', res.data);
                } else {
                    commit('setAdsDashboard', {});
                }
                commit('setAdsDashboardIsLoading', false);
            })
            .catch((err) => {
                console.log("err: ", err);
                // alert(err);
                commit('setAdsDashboardIsLoading', false);
            });
    },
};

const getters = {
    adsDashboard(state) {
        return state.adsDashboard;
    },
    adsDashboardIsLoading(state) {
        return state.adsDashboardIsLoading;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
