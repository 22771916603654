// Animal Ref
// 1,Rat,_子
// 2,Ox,_丑
// 3,Tiger,_寅
// 4,Rabbit,_卯
// 5,Dragon,_辰
// 6,Snake,_巳
// 7,Horse,_午
// 8,Sheep,_未
// 9,Monkey,_申
// 10,Rooster,_酉
// 11,Dog,_戌
// 12,Boar,_亥

// Breaker Day
export const breakerParse = {
  子: '午',
  丑: '未',
  寅: '申',
  卯: '酉',
  辰: '戌',
  巳: '亥',
  午: '子',
  未: '丑',
  申: '寅',
  酉: '卯',
  戌: '辰',
  亥: '巳',
}

// 6 Yearly/MonthlyThree killings
export const robberyShaParse = {
  子: '巳',
  丑: '寅',
  寅: '亥',
  卯: '申',
  辰: '巳',
  巳: '寅',
  午: '亥',
  未: '申',
  申: '巳',
  酉: '寅',
  戌: '亥',
  亥: '申',
}

export const calamityShaParse = {
  子: '午',
  丑: '卯',
  寅: '子',
  卯: '酉',
  辰: '午',
  巳: '卯',
  午: '子',
  未: '酉',
  申: '午',
  酉: '卯',
  戌: '子',
  亥: '酉',
}

export const annualShaParse = {
  子: '未',
  丑: '辰',
  寅: '丑',
  卯: '戌',
  辰: '未',
  巳: '辰',
  午: '丑',
  未: '戌',
  申: '未',
  酉: '辰',
  戌: '丑',
  亥: '戌',
}

// 7 No wealth days
export const noWealthDayParse = {
  0: [],
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
  6: [],
  7: [],
  8: [],
  9: [],
  10: [],
  11: [],
  12: [],
  13: [],
  14: [],
  15: [],
  16: [],
  17: [],
  18: [],
  19: [],
  20: [],
  21: [],
  22: [],
  23: [],
  24: [],
  25: [],
  26: [],
  27: [],
  28: [],
  29: [],
  30: [],
  31: [],
  32: [],
  33: [],
  34: [],
  35: [],
  36: [],
  37: [],
  38: [],
  39: [33, 35],
  40: [33, 35],
  41: [41, 42],
  42: [41, 42],
  43: [41, 42],
  44: [41, 42],
  45: [41, 42],
  46: [41, 42],
  47: [41, 42],
  48: [41, 42],
  49: [41, 42],
  50: [41, 42],
  51: [41, 42],
  52: [41, 42],
  53: [41, 42],
  54: [41, 42],
  55: [41, 42],
  56: [41, 42],
  57: [41, 42],
  58: [41, 42],
  59: [41, 42],
  60: [41, 42],
}

// 8 Personal Wealth Sky Horse
export const personalWealthParse = {
  子: '寅',
  丑: '亥',
  寅: '申',
  卯: '巳',
  辰: '寅',
  巳: '亥',
  午: '申',
  未: '巳',
  申: '寅',
  酉: '亥',
  戌: '申',
  亥: '巳',
}
// No wealth days

// 11 The personal breaker day
export const personalBreakerParse = {
  子: '午',
  丑: '未',
  寅: '申',
  卯: '酉',
  辰: '戌',
  巳: '亥',
  午: '子',
  未: '丑',
  申: '寅',
  酉: '卯',
  戌: '辰',
  亥: '巳',
}
// 12 combine animal
export const combineAnimalParse = {
  子: '丑',
  丑: '子',
  寅: '亥',
  卯: '戌',
  辰: '酉',
  巳: '申',
  午: '未',
  未: '午',
  申: '巳',
  酉: '辰',
  戌: '卯',
  亥: '寅',
}

// 14 Solitary Star
export const solitaryStarGroomParse = {
  子: '寅',
  丑: '寅',
  寅: '巳',
  卯: '巳',
  辰: '巳',
  巳: '申',
  午: '申',
  未: '申',
  申: '亥',
  酉: '亥',
  戌: '亥',
  亥: '寅',
}

// 14 Solitary Star
export const solitaryStarBrideParse = {
  子: '戌',
  丑: '戌',
  寅: '丑',
  卯: '丑',
  辰: '丑',
  巳: '辰',
  午: '辰',
  未: '辰',
  申: '未',
  酉: '未',
  戌: '未',
  亥: '戌',
}

// 15 Sky Happiness Star
export const skyHappinessStarParse = {
  子: '申',
  丑: '酉',
  寅: '戌',
  卯: '亥',
  辰: '子',
  巳: '丑',
  午: '寅',
  未: '卯',
  申: '辰',
  酉: '巳',
  戌: '午',
  亥: '未',
}
// 16 Year Prosperity Star
export const yearProsperityParse = {
  甲: '寅',
  乙: '卯',
  丙: '巳',
  丁: '午',
  戊: '巳',
  己: '午',
  庚: '申',
  辛: '酉',
  壬: '亥',
  癸: '子',
}
// Asset acquisition
export const assetAcquireParse = {
  甲: ['未'],
  丙: ['戌'],
  戊: ['辰', '戌', '丑', '未'],
  庚: ['丑'],
  壬: ['辰'],
  乙: ['辰', '戌', '丑', '未'],
  丁: ['戌'],
  己: ['未'],
  辛: ['丑'],
  癸: ['辰'],
}
// 18 Heavenly Wealth Star
export const heavenlyWealthStarParse = {
  甲: '戊',
  乙: '己',
  丙: '庚',
  丁: '辛',
  戊: '壬',
  己: '癸',
  庚: '甲',
  辛: '乙',
  壬: '丙',
  癸: '丁',
}
// 19 Personal Extinction Star
export const personalExtinctionStarParse = {
  甲: '申',
  乙: '酉',
  丙: '亥',
  丁: '子',
  戊: '亥',
  己: '子',
  庚: '寅',
  辛: '卯',
  壬: '巳',
  癸: '午',
}
// 20 Illness Star 病符星
export const illnessStarParse = {
  子: '亥',
  丑: '子',
  寅: '丑',
  卯: '寅',
  辰: '卯',
  巳: '辰',
  午: '巳',
  未: '午',
  申: '未',
  酉: '申',
  戌: '酉',
  亥: '戌',
}
// 21 Heavenly Doctor Star
export const heavenlyDoctorStarParse = {
  子: '亥',
  丑: '子',
  寅: '丑',
  卯: '寅',
  辰: '卯',
  巳: '辰',
  午: '巳',
  未: '午',
  申: '未',
  酉: '申',
  戌: '酉',
  亥: '戌',
}
// 22 Starting diet
export const startingDietParse = {
  子: '午',
  丑: '未',
  寅: '申',
  卯: '酉',
  辰: '戌',
  巳: '亥',
  午: '子',
  未: '丑',
  申: '寅',
  酉: '卯',
  戌: '辰',
  亥: '巳',
}
// 23 Personal Nobleman
export const personalNobleManParse = {
  甲: ['丑', '未'],
  乙: ['子', '申'],
  丙: ['亥', '酉'],
  壬: ['卯', '巳'],
  辛: ['午', '寅'],
  戊: ['丑', '未'],
  己: ['子', '申'],
  丁: ['亥', '酉'],
  癸: ['卯', '巳'],
  庚: ['丑', '未'],
}
// 25 heavenlyVirtueParse
export const heavenlyVirtueParse = {
  子: '丁',
  丑: '申',
  寅: '壬',
  卯: '辛',
  辰: '亥',
  巳: '甲',
  午: '癸',
  未: '寅',
  申: '丙',
  酉: '乙',
  戌: '巳',
  亥: '庚',
}
// 26 monhlyVirtue
export const monthlyVirtueParse = {
  子: '壬',
  丑: '庚',
  寅: '丙',
  卯: '甲',
  辰: '壬',
  巳: '庚',
  午: '丙',
  未: '甲',
  申: '壬',
  酉: '庚',
  戌: '丙',
  亥: '甲',
}
// 27 threeHarmony
export const threeHarmonyPartialParse = {
  子: [],
  丑: ['巳', '酉'],
  寅: [],
  卯: [],
  辰: ['子', '申'],
  巳: [],
  午: [],
  未: ['亥', '卯'],
  申: [],
  酉: [],
  戌: ['寅', '午'],
  亥: [],
}

export const threeHarmonySetGroup = {
  0: [0, 0, 0],
  子: ['寅', '午', '戌'],
  丑: ['卯', '未', '亥'],
  寅: ['子', '辰', '申'],
  卯: ['丑', '巳', '酉'],
}

export const threeHarmonyDaySet = {
  子: 0,
  丑: '卯',
  寅: 0,
  卯: 0,
  辰: '寅',
  巳: 0,
  午: 0,
  未: '丑',
  申: 0,
  酉: 0,
  戌: '子',
  亥: 0,
}
