<template>
    <component :is="iconComponent" :class="className" :style="{ fill: color }" />
</template>

<script>
const icons = {};

const requireComponents = require.context('../assets/svgs/', false, /.svg$/);

requireComponents.keys().forEach((fileName) => {
    const iconName = fileName.replace(/^\.\/(.+)\.svg$/, '$1');
    const componentConfig = requireComponents(fileName);
    icons[iconName] = componentConfig.default || componentConfig;
});

export default {
    props: {
        className: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            required: true,
            validator(value) {
                return Object.prototype.hasOwnProperty.call(icons, value);
            },
        },
        color: {
            type: String,
            default: 'currentColor'
        },
    },

    computed: {
        iconComponent() {
            return icons[this.name];
        },
    },
};
</script>
