import Vue from 'vue'
import Vuex from 'vuex'

import ads from './modules/ads'
import adsDashboard from './modules/ads-dashboard'
import adsReports from './modules/ads-reports'
import advertisement from './modules/advertisement'
import alert from './modules/alert'
import auspiciousSelection from './modules/auspicious-selection'
import calendar from './modules/calendar'
import checkboxes from './modules/checkboxes'
import customers from './modules/customers'
import dashboard from './modules/dashboard'
import dateSelection from './modules/date-selection'
import dateSelectionCalendar from './modules/date-selection-calendar'
import dateSelectionDetails from './modules/date-selection-details'
import dateSelection2 from './modules/date-selection2'
import feedback from './modules/feedback'
import fortune from './modules/fortune-telling'
import login from './modules/login'
import notifications from './modules/notifications'
import selectOptions from './modules/select-options'
import stock from './modules/stock'
import users from './modules/users'
import users2 from './modules/users2'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    login,
    users,
    calendar,
    fortune,
    dateSelection,
    dateSelectionCalendar,
    dateSelection2,
    alert,
    dashboard,
    customers,
    ads,
    adsReports,
    selectOptions,
    checkboxes,
    advertisement,
    auspiciousSelection,
    adsDashboard,
    feedback,
    users2,
    stock,
    notifications,
    dateSelectionDetails,
  },
})
