const state = {
	message: null,
    isAlert: false,
    alertType: null,
};

const mutations = {
	// Authorize
	setAlert(state, {message, type}) {
		state.message = message;
		state.alertType= type;
        state.isAlert = true;
        setTimeout(()=> {
            state.isAlert = false
        },5000)
	},
    closeAlert(state){
        state.isAlert = false
    },
};

const actions = {
	setAlertMessage: async ({ commit },{message, type}) => {
        commit('setAlert', {message, type})
	},
	closeAlert: async ({ commit }) => {
        commit('closeAlert')
	},
};

const getters = {
	// get if user is authorized
	message(state) {
		return state.message;
	},
	isAlert(state) {
		return state.isAlert;
	},
	alertType(state) {
		return state.alertType;
	},
};

export default {
	state,
	mutations,
	actions,
	getters,
};
