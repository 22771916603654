// '子',Rat,_
// '丑',Ox,_
// '寅',Tiger,_
// '卯',Rabbit,_
// '辰',Dragon,_
// '巳',Snake,_
// '午',Horse,_
// '未',Sheep,_
// '申',Monkey,_
// '酉',Rooster,_
// '戌',Dog,_
// '亥',Boar,_

// getYearBranch()
export const getYearBranchTable = {
    '子': ['卯', '未', '寅', '丑', '酉', '亥', '寅'],
    '丑': ['辰', '巳', '未', '戌', '午', '申', '寅'],
    '寅': ['亥', '辰', '申', '巳', '卯', '巳', '巳'],
    '卯': ['午', '寅', '辰', '酉', '子', '寅', '巳'],
    '辰': ['丑', '未', '寅', '丑', '酉', '亥', '巳'],
    '巳': ['寅', '巳', '未', '戌', '午', '申', '申'],
    '午': ['酉', '辰', '申', '巳', '卯', '巳', '申'],
    '未': ['戌', '寅', '辰', '酉', '子', '寅', '申'],
    '申': ['巳', '未', '寅', '丑', '酉', '亥', '亥'],
    '酉': ['子', '巳', '未', '戌', '午', '申', '亥'],
    '戌': ['未', '辰', '申', '巳', '卯', '巳', '亥'],
    '亥': ['申', '寅', '辰', '酉', '子', '寅', '寅'],
};

// getMonthBranch()
export const getMonthBranchTable = {
    '子': ['子', "Ren", "Bing", '巳', '辰'],
    '丑': ["Geng", "Geng", "Jia", '申', '酉'],
    '寅': ["Ding", "Bing", "Ren", '亥', '午'],
    '卯': ['申', "Jia", "Geng", '寅', '亥'],
    '辰': ["Ren", "Ren", "Bing", '巳', '申'],
    '巳': ["Xin", "Geng", "Jia", '申', '丑'],
    '午': ['亥', "Bing", "Ren", '亥', '戌'],
    '未': ["Jia", "Jia", "Geng", '寅', '卯'],
    '申': ["Gui", "Ren", "Bing", '巳', '子'],
    '酉': ['寅', "Geng", "Jia", '申', '巳'],
    '戌': ["Bing", "Bing", "Ren", '亥', '寅'],
    '亥': ["Yi", "Jia", "Geng", '寅', '未'],
};

// getDayMonthAuxilaryStar()
export const zodiacTable = {
    '子': ['未', '子', '卯', '戌', '卯', '午', '午', '辰', '酉', '戌', '辰', '卯'],
    '丑': ['申', '酉', '寅', '亥', '辰', '未', '未', '巳', '午', '酉', '卯', '戌'],
    '寅': ['酉', '午', '丑', '子', '巳', '申', '申', '午', '卯', '申', '寅', '巳'],
    '卯': ['戌', '卯', '子', '丑', '午', '酉', '酉', '未', '子', '未', '丑', '子'],
    '辰': ['亥', '子', '亥', '寅', '未', '戌', '戌', '申', '酉', '午', '子', '辰'],
    '巳': ['亥', '酉', '戌', '卯', '申', '亥', '亥', '酉', '午', '巳', '亥', '申'],
    '午': ['丑', '午', '酉', '辰', '酉', '子', '子', '戌', '卯', '辰', '戌', '午'],
    '未': ['寅', '卯', '申', '巳', '戌', '丑', '丑', '亥', '子', '卯', '酉', '丑'],
    '申': ['卯', '子', '未', '午', '亥', '寅', '寅', '子', '酉', '寅', '申', '寅'],
    '酉': ['辰', '酉', '午', '未', '子', '卯', '卯', '丑', '午', '丑', '未', '酉'],
    '戌': ['巳', '午', '巳', '申', '丑', '辰', '辰', '寅', '卯', '子', '午', '未'],
    '亥': ['午', '卯', '辰', '酉', '寅', '巳', '巳', '卯', '子', '亥', '巳', '亥'],
};

export const zodiacTable2 = {
    '子': ['Fire','Water', '未', '午', '亥', '酉', '申', '辰', '卯', '丑', '子'],
    '丑': ['Wood','Metal', '辰', '卯', '子', '戌', '酉', '巳', '辰', '寅', '丑'],
    '寅': ['Water','Fire', '丑', '子', '丑', '亥', '戌', '午', '巳', '卯', '寅'],
    '卯': ['Metal','Wood', '戌', '酉', '寅', '子', '亥', '未', '午', '辰', '卯'],
    '辰': ['Fire', 'Water','未', '午', '卯', '丑', '子', '申', '未', '巳', '辰'],
    '巳': ['Wood', 'Metal','辰', '卯', '辰', '寅', '丑', '酉', '申', '午', '巳'],
    '午': ['Water','Fire', '丑', '子', '巳', '卯', '寅', '戌', '酉', '未', '午'],
    '未': ['Metal','Wood', '戌', '酉', '午', '辰', '卯', '亥', '戌', '申', '未'],
    '申': ['Fire', 'Water','未', '午', '未', '巳', '辰', '子', '亥', '酉', '申'],
    '酉': ['Wood', 'Metal','辰', '卯', '申', '午', '巳', '丑', '子', '戌', '酉'],
    '戌': ['Water','Fire', '丑', '子', '酉', '未', '午', '寅', '丑', '亥', '戌'],
    '亥': ['Metal','Wood', '戌', '酉', '戌', '申', '未', '卯', '寅', '子', '亥'],
};

export const dayStemTable = {
    'Yang Wood': [['未', '丑'], '子', '巳', '寅', '卯', ['申', '午'], '亥', '酉', '酉', 'Yang Earth', '辰'],
    'Yin Wood': [['申', '子'], '子', '午', '卯', '寅', ['申', '午'], '午', '戌', '戌', 'Yang Earth', '巳'],
    'Yang Water': [['酉', '亥'], '卯', '申', '巳', '午', '寅', '寅', '未', '子', 'Yang Metal', '巳'],
    'Yin Water': [['酉', '亥'], '卯', '酉', '午', '巳', '未', '酉', '申', '丑', 'Yang Metal', '申'],
    'Yang Metal': [['丑', '未'], "-", '申', '巳', '午', '辰', '寅', '巳', '子', 'Yang Water', '未'],
    'Yin Metal': [['子', '申'], "-", '酉', '午', '巳', '辰', '酉', '午', '丑', 'Yang Water', '申'],
    'Yang Fire': [['丑', '未'], '午', '亥', '申', '酉', '戌', '巳', '辰', '卯', 'Yang Wood', '戌'],
    'Yin Fire': [['午', '寅'], '午', '子', '丑', '申', '酉', '子', '卯', '辰', 'Yang Wood', '亥'],
    'Yang Earth': [['卯', '巳'], '酉', '寅', '亥', '子', '子', '申', '寅', '午', 'Yang Fire', '丑'],
    'Yin Earth': [['卯', '巳'], '酉', '卯', '子', '亥', '申', '卯', '亥', '未', 'Yang Fire', '寅'],
};
export const dayBranchTable = {
    '子': ['辰', '子', '寅', '酉', '戌', '寅', '戌', '寅', '巳', '寅', '亥'],
    '丑': ['丑', '酉', '亥', '午', '酉', '寅', '戌', '卯', '寅', '卯', '申'],
    '寅': ['戌', '午', '申', '卯', '申', '巳', '丑', '辰', '亥', '辰', '巳'],
    '卯': ['未', '卯', '巳', '子', '未', '巳', '丑', '巳', '申', '亥', '寅'],
    '辰': ['辰', '子', '寅', '酉', '午', '巳', '丑', '午', '巳', '午', '亥'],
    '巳': ['丑', '酉', '亥', '午', '巳', '申', '辰', '未', '寅', '未', '申'],
    '午': ['戌', '午', '申', '卯', '辰', '申', '辰', '申', '亥', '申', '巳'],
    '未': ['未', '卯', '巳', '子', '卯', '申', '辰', '酉', '申', '酉', '寅'],
    '申': ['辰', '子', '寅', '酉', '寅', '亥', '未', '戌', '巳', '戌', '亥'],
    '酉': ['丑', '酉', '亥', '午', '丑', '亥', '未', '亥', '寅', '亥', '申'],
    '戌': ['戌', '午', '申', '卯', '子', '亥', '未', '子', '亥', '子', '巳'],
    '亥': ['未', '卯', '巳', '子', '亥', '寅', '戌', '丑', '申', '丑', '寅'],
};

