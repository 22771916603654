import axios from '@/api/axios-ads-reports';

const state = {
    adsReports: [],
    adsReportsTotalPage: 0,
    adsReportsIsLoading: false,

    adsReportsById: [],
    adsReportsByIdTotalPage: 0,
    adsReportsByIdIsLoading: false,
};

const mutations = {
    setAdsReports(state, adsReports) {
        state.adsReports = adsReports;
    },
    setAdsReportsTotalPage(state, pages) {
        state.adsReportsTotalPage = pages;
    },
    setAdsReportsIsLoading(state, status) {
        state.adsReportsIsLoading = status;
    },

    setAdsReportsById(state, adsReports) {
        state.adsReportsById = adsReports;
    },
    setAdsReportsByIdTotalPage(state, pages) {
        state.adsReportsByIdTotalPage = pages;
    },
    setAdsReportsByIdIsLoading(state, status) {
        state.adsReportsByIdIsLoading = status;
    },
};

const actions = {
    getAdsReports: async (
        { commit, getters },
        {
            page = 1,
            limit = 15,
            os = 'all',
            user_type = 'all',
            gender = 'all',
            start_age = null,
            end_age = null,
            start_date = null,
            end_date = null,
            customer_name_search = null,
            auspicious_type = null,
            ad_type = null,
            ad_position = null,
            country = null,
            province = null,
        }
    ) => {
        commit('setAdsReportsIsLoading', true);

        let params = {
            page: page,
            limit: limit,
            os: os,
            user_type: user_type,
            gender: gender,
            start_age: start_age,
            end_age: end_age,
            start_date: start_date,
            end_date: end_date,
            customer_name_search: customer_name_search,
            auspicious_type: auspicious_type,
            ad_type: ad_type,
            ad_position: ad_position,
            country: country,
            province: province,
        };

        await axios
            .get('/ads-reports', {
                params: params,
            })
            .then((res) => {
                if (res.data.adReports !== null) {
                    commit('setAdsReports', res.data.adReports);
                    commit(
                        'setAdsReportsTotalPage',
                        res.data.meta.pagination.pages
                    );
                } else {
                    commit('setAdsReports', []);
                }
                commit('setAdsReportsIsLoading', false);
            })
            .catch((err) => {
                // alert(err);
                commit('setAdsReportsIsLoading', false);
            });
    },

    getAdsReportsById: async (
        { commit, getters },
        {
            ad_id = null,
            page = 1,
            limit = 15,
            os = 'all',
            user_type = 'all',
            gender = 'all',
            start_age = null,
            end_age = null,
            start_date = null,
            end_date = null,
            customer_name_search = null,
            auspicious_type = null,
            ad_type = null,
            ad_position = null,
            country = null,
            province = null,
        }
    ) => {
        commit('setAdsReportsByIdIsLoading', true);

        let params = {
            ad_id: ad_id,
            page: page,
            limit: limit,
            os: os,
            user_type: user_type,
            gender: gender,
            start_age: start_age,
            end_age: end_age,
            start_date: start_date,
            end_date: end_date,
            customer_name_search: customer_name_search,
            auspicious_type: auspicious_type,
            ad_type: ad_type,
            ad_position: ad_position,
            country: country,
            province: province,
        };

        await axios
            .get('/ads-reports/' + params.ad_id, {
                params: params,
            })
            .then((res) => {
                if (res.data.adReports !== null) {
                    commit('setAdsReportsById', res.data.adReports);
                    commit(
                        'setAdsReportsByIdTotalPage',
                        res.data.meta.pagination.pages
                    );
                } else {
                    commit('setAdsReportsById', []);
                }
                commit('setAdsReportsByIdIsLoading', false);
            })
            .catch((err) => {
                // alert(err);
                commit('setAdsReportsByIdIsLoading', false);
            });
    },
};

const getters = {
    adsReports(state) {
        return state.adsReports;
    },
    adsReportsTotalPage(state) {
        return state.adsReportsTotalPage;
    },
    adsReportsIsLoading(state) {
        return state.adsReportsIsLoading;
    },

    adsReportsById(state) {
        return state.adsReportsById;
    },
    adsReportsByIdTotalPage(state) {
        return state.adsReportsByIdTotalPage;
    },
    adsReportsByIdIsLoading(state) {
        return state.adsReportsByIdIsLoading;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
