import axios from '@/api/axios-date-selection.js';
import router from '../../router';
import requestType from '@/assets/date-selection/request-type-enum.js';

const state = {
  requests: [],
  request: [],
  numberOfFile: null,
};

const mutations = {
  setRequests(state, requests) {
    state.requests = requests;
  },
  setRequest(state, request) {
    state.request = request;
  },
  clearRequest(state) {
    state.request = [];
  },
  setNumberOfFile(state, file) {
    state.numberOfFile = file;
  },
  setCurrentRequestId(state, id) {
    state.currentRequestId = id;
  },
};

const actions = {
  getAllRequest: async ({ commit, dispatch }) => {
    await axios
      .get('/astro/read-all')
      .then((res) => {
        // sort date of each request
        let data = res.data;
        data = data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        data = data.map((r) => {
          let obj = JSON.parse(r.detail);
          let contact_name = '';
          if (obj.contact_name) {
            contact_name = obj.contact_name;
          }
          return {
            ...r,
            contact_name: contact_name,
          };
        });
        commit('setRequests', data);
      })
      .catch((err) => {
        dispatch('setAlertMessage', { message: 'Email หรือ รหัสผ่านไม่ถูกต้อง', type: 'error' });
      });
  },

  getRequest: async ({ commit, dispatch }, request) => {
    commit('clearRequest');
    // get request
    await axios
      .get('/astro/read', {
        params: { sel_id: request.sel_id },
      })
      .then((res) => {
        dispatch('getPersons', { detail: JSON.parse(res.data.detail), type: res.data.type_code });
        commit('setAuspiciousType', res.data.type_code);
        let auspiciousSelection = {
          date1: null,
          date2: null,
          time1: null,
          time2: null,
        };
        if (res.data.type_code == 1) {
          dispatch('setCurrentAuspicious', auspiciousSelection);
        } else {
          let auspiciousSelection = {
            date: null,
            time: null,
          };
          dispatch('setCurrentAuspicious', auspiciousSelection);
        }
        commit('clearSelectedAuspicious');
        commit('setRequest', res.data);
        commit('setCurrentRequestId', request.sel_id);
      });
    await axios.get(`/astro/files/${request.sel_id}/99`).then((res) => {
      commit('setNumberOfFile', res.data.number_of_files);
    });
  },
  deleteRequest: async ({ dispatch }, requestId) => {
    let form = { data: { "sel_id": parseInt(requestId) }}
    await axios
      .delete('/astro/delete', form)
      .then((res) => {
        dispatch('setAlertMessage', { message: 'ลบ Request สำเร็จ', type: 'success' });
      })
      .catch((err) => {
        dispatch('setAlertMessage', { message: 'เกิดข้อผิดพลาด', type: 'error' });
      });
  },
  getPersons: async ({ dispatch }, { detail, type }) => {
    let persons = getPersons(detail, type);
    dispatch('getPersonBazi', persons);
  },
  getPersonBazi: async ({ commit, getters, dispatch }, personsData) => {
    let persons = personsData;
    let promises = [];
    let type = getters.auspiciousType;
    persons.forEach((e, index) => {
      let promise = new Promise((resolve, reject) => {
        if (e.dob !== null) {
          axios
            .get(`/calendar/bazi/${e.dob}/${e.tob}`)
            .then((res) => {
              resolve({
                ...e,
                index: index,
                isActive: initialActiveFilter(type, index),
                bazi: res.data,
              });
              // this.$store.dispatch('addPersonBazi', {
              //     bazi: res.data,
              //     title: this.title
              // })
            })
            .catch((err) => {
              reject({ ...e });
            });
        } else {
          resolve({
            ...e,
            index: index,
            isActive: initialActiveFilter(type, index),
            bazi: null,
          });
        }
      });
      promises.push(promise);
    });
    await Promise.allSettled(promises).then((data) => {
      let result = data.filter((e) => e.status == 'fulfilled').map((e) => e.value);
      commit('setPersons', result);
      let today = new Date();
      let month = today.getMonth() + 1;
      let year = today.getFullYear();

      var date = new Date()
      date.setDate(1)
      date.setMonth(date.getMonth()) // lastMonth
      let lastMonth = date.getMonth()
      let lastMonthYear = date.getFullYear()
      date.setMonth(date.getMonth()+2) // Next Month
      let nextMonth = date.getMonth()
      let nextMonthYear = date.getFullYear()
      
      dispatch('getAuspiciousCalendar', {
        year: year,
        month: month,
      });
    });
  },

  submitAuspiciousDateTime: async ({ commit, dispatch }, request) => {
    await axios
      .post(`/astro/complete/${request.user_id}`, {
        sel_id: parseInt(request.sel_id),
        response: JSON.stringify(request.response),
      })
      .then(() => {
        dispatch('getRequest', {
          sel_id: request.sel_id,
        });
        dispatch('setAlertMessage', { message: 'Auspicious date are submitted', type: 'success' });
      });
  },
  submitCandidateDate: async ({ commit }, request) => {
    let form = {
      sel_id: parseInt(request.sel_id),
      candidate_date: JSON.stringify(request.selectedDate),
      response: JSON.stringify(''),
    };
    await axios.post(`/astro/update-candidate/${request.user_id}`, form).then(() => {
      dispatch('setAlertMessage', { message: 'Candidate dates are submitted!', type: 'success' });
      router.go(-1);
    });
  },
};

const getters = {
  requests: (state) => {
    return state.requests;
  },
  request: (state) => {
    return state.request;
  },
  numberOfFile: (state) => {
    return state.numberOfFile;
  },
  currentRequestId: (state) => {
    return state.currentRequestId;
  },
  // user: (state) => {
  // 	return state.user;
  // },
};

export default {
  state,
  mutations,
  actions,
  getters,
};

function getPersons(detail, type) {
  let persons = [];
  switch (type) {
    case requestType['Marriage']:
      {
        let groomName = `${detail['groom_first_name']} ${detail['groom_last_name']}`;
        let brideName = `${detail['bride_first_name']} ${detail['bride_last_name']}`;
        let groom = personFactory('เจ้าบ่าว', 'G', groomName, detail['groom_dob'], detail['groom_tob']);
        let bride = personFactory('เจ้าสาว', 'B', brideName, detail['bride_dob'], detail['bride_tob']);
        let groomFather = personFactory('พ่อของเจ้าบ่าว', 'P1(GF)', '', detail['groom_father_dob'], null);
        let groomMother = personFactory('แม่ของเจ้าบ่าว', 'P2(GM)', '', detail['groom_mother_dob'], null);
        let brideFather = personFactory('พ่อของเจ้าสาว', 'P3(BF)', '', detail['bride_father_dob'], null);
        let brideMother = personFactory('แม่ของเจ้าสาว', 'P4(BM)', '', detail['bride_mother_dob'], null);
        persons = [groom, bride, groomFather, groomMother, brideFather, brideMother];
      }
      break;
    case requestType['New-Car']:
      {
        let name = `${detail['first_name']} ${detail['last_name']}`;
        let owner = personFactory('เจ้าของรถ', 'P', name, detail['dob'], detail['tob']);
        persons = [owner];
      }
      break;
    case requestType['Birth']:
      {
        let fatherName = `${detail['mother_first_name']} ${detail['mother_last_name']}`;
        let motherName = `${detail['father_first_name']} ${detail['father_last_name']}`;
        let father = personFactory('บิดา', 'FT', fatherName, detail['father_dob'], detail['father_tob']);
        let mother = personFactory('มารดา', 'MT', motherName, detail['mother_dob'], detail['mother_tob']);
        let child = personFactory('บุตร', 'B', '', null, null);
        child = {
          ...child,
          baby_gender: detail.baby_gender,
          baby_yob: detail.baby_yob,
        };
        persons = [child, father, mother];
      }
      break;
    case requestType['Building-Construction']:
      {
        persons = detail.landowners;
        persons = persons.map((p, index) => {
          let name = `${p['first_name']} ${p['last_name']}`;
          return personFactory(`เจ้าของที่ดินคนที่ ${index + 1}: ${p.status}`, `M${index + 1}`, name, p.dob, p.tob);
        });
      }
      break;
    case requestType['House-Construction']:
      {
        persons = detail.members;
        persons = persons.map((p, index) => {
          let name = `${p['first_name']} ${p['last_name']}`;
          return personFactory(`เจ้าของบ้านคนที่ ${index + 1}: ${p.status}`, `M${index + 1}`, name, p.dob, p.tob);
        });
      }
      break;
    case requestType['House-Moving']:
      {
        persons = detail.members;
        persons = persons.map((p, index) => {
          let name = `${p['first_name']} ${p['last_name']}`;
          return personFactory(`ผู้อาศัยคนที่ ${index + 1}: ${p.status}`, `M${index + 1}`, name, p.dob, p.tob);
        });
      }
      break;
    case requestType['Contract-Signing']:
      {
        persons = detail.members;
        persons = persons.map((p, index) => {
          let name = `${p['first_name']} ${p['last_name']}`;
          return personFactory(`ผู้ร่วมธุรกิจคนที่ ${index + 1}`, `M${index + 1}`, name, p.dob, p.tob);
        });
      }
      break;
    case requestType['Business-Opening']:
      {
        persons = detail.members;
        persons = persons.map((p, index) => {
          let name = `${p['first_name']} ${p['last_name']}`;
          return personFactory(`ผู้เกี่ยวข้องที่ ${index + 1}: ${p.relationship}`, `M${index + 1}`, name, p.dob, p.tob);
        });
      }
      break;
    case requestType['Surgery']:
      {
        let name = `${detail['first_name']} ${detail['last_name']}`;
        let owner = personFactory('ผู้ร้องขอ', 'P', name, detail['dob'], detail['tob']);
        persons = [owner];
      }
      break;
    case requestType['Treatment']:
      {
        let name = `${detail['first_name']} ${detail['last_name']}`;
        let owner = personFactory('ผู้ร้องขอ', 'P', name, detail['dob'], detail['tob']);
        persons = [owner];
      }
      break;
    case requestType['Cosmetic-Surgery']:
      {
        let name = `${detail['first_name']} ${detail['last_name']}`;
        let owner = personFactory('ผู้ร้องขอ', 'P', name, detail['dob'], detail['tob']);
        persons = [owner];
      }
      break;
  }
  return persons;
}

function personFactory(title, acronym, name, dob, tob) {
  return {
    title: title,
    acronym: acronym,
    name: name,
    dob: !isEmpty(dob) ? dob : null,
    tob: !isEmpty(tob) ? tob : 'null',
  };
}

function initialActiveFilter(type, index) {
  switch (type) {
    case requestType['Marriage']:
      if (index <= 1) return true;
      return false;
    case requestType['New-Car']:
      return true;
    case requestType['Birth']:
      return true;
    case requestType['Building-Construction']:
      if (index <= 0) return true;
      return true;
    case requestType['House-Construction']:
      if (index <= 0) return true;
      return true;
    case requestType['House-Moving']:
      if (index <= 0) return true;
      return true;
    case requestType['Contract-Signing']:
      if (index <= 0) return true;
      return true;
    case requestType['Business-Opening']:
      if (index <= 0) return true;
      return true;
    case requestType['Surgery']:
      return true;
    case requestType['Treatment']:
      return true;
    case requestType['Cosmetic-Surgery']:
      return true;
    default:
      return true;
  }
}

function isEmpty(obj) {
  return typeof obj == 'undefined' || obj == '' || obj == null || obj == '-';
}
