import axios from '@/api/axios-customers';

const state = {
    customers: [],
    isLoadingCustomers: false,
};

const mutations = {
    setCustomers(state, customers) {
        state.customers = customers;
    },
    setIsLoadingCustomers(state, status) {
        state.isLoadingCustomers = status;
    },
};

const actions = {
    getCustomers: async (
        { commit, getters },
        { searchString = null, isSearching = false }
    ) => {
        commit('setIsLoadingCustomers', true);
        let params = {};
        if (searchString) params['search'] = searchString;
        await axios
            .get('/customers', {
                params: params,
            })
            .then((res) => {
                if (res.data !== null) commit('setCustomers', res.data);
                else commit('setCustomers', []);
                commit('setIsLoadingCustomers', false);
            })
            .catch((err) => {
                commit('setIsLoadingCustomers', false);
                // alert(err);
            });
    },
};

const getters = {
    customers: (state) => {
        return state.customers;
    },
    isLoadingCustomers: (state) => {
        return state.isLoadingCustomers;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
