// Parse element code
// export const totalPersonInAuspiciousType = {
//   1: 6,
//   2: 1,
//   3: 2,
//   4: 1,
//   5: 1,
//   6: 1,
//   7: 1,
//   8: 1,
//   9: 1,
//   10: 1,
//   11: 1,
// };

export const parseDate = [
  '',
  '初一',
  '初二',
  '初三',
  '初四',
  '初五',
  '初六',
  '初七',
  '初八',
  '初九',
  '初十',
  '十一',
  '十二',
  '十三',
  '十四',
  '十五',
  '十六',
  '十七',
  '十八',
  '十九',
  '二十',
  '廿一',
  '廿二',
  '廿三',
  '廿四',
  '廿五',
  '廿六',
  '廿七',
  '廿八',
  '廿九',
  '三十',
];
export const parseMonth = [
  '',
  '正月',
  '二月',
  '三月',
  '四月',
  '五月',
  '六月',
  '七月',
  '八月',
  '九月',
  '十月',
  '十一月',
  '十二月',
  '闰', // -minus month
];

// day_constellation
export const dayConstellationParse = [
  '',
  '角 Horn',
  '亢 Neck',
  '氐 Foundation',
  '房 House',
  '心 Heart',
  '尾 Tail',
  '箕 Basket',
  '斗 Dipper',
  '牛 Ox',
  '女 Weaving Maiden',
  '虛 Void',
  '危 Danger',
  '室 Room',
  '壁 Wall',
  '奎 Astride',
  '婁 Mound',
  '胃 Stomach',
  '昂 Pleiades',
  '畢 Net',
  '觜 Beak',
  '參 Orion',
  '井 Well',
  '鬼 Ghost',
  '柳 Willow',
  '星 Star',
  '張 Bow',
  '翌 Wing',
  '軫 Carriage',
];

// day_officer
export const dayOfficerParse = [
  '',
  '建 Establish',
  '除 Remove',
  '滿 Full',
  '平 Balance',
  '定 Stable',
  '執 Initiate',
  '破 Destruction',
  '危 Danger',
  '成 Success',
  '收 Receive',
  '開 Open',
  '閉 Close',
];

