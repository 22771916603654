import axios from '@/api/axios-users';

const state = {
  age: [],
  gender: {},
  sign_in_method: {},
  relationship: {},
  growth: { users: [], key: 0 },
};

const mutations = {
  // Authorize
  setOverall(state, { age, gender, relationship, sign_in_method }) {
    state.age = age;
    state.gender = gender;
    state.relationship = relationship;
    state.sign_in_method = sign_in_method;
  },
  setGrowth(state, growth) {
    state.growth = growth;
  },
};

const actions = {
  getDashboard: async ({ commit }) => {
    axios.get('/users/analytics/overall').then((res) => {
      let { age, gender, relationship, sign_in_method } = res.data;
      commit('setOverall', { age, gender, relationship, sign_in_method });
    });
  },
  getUserGrowth: async ({ commit }, { type, to, from }) => {
    let params = { time_range: type };
    if (to !== null) {
      params.to = to;
      params.from = from;
    }
    axios.get('/users/analytics/user-growth', { params: params }).then((res) => {
      let { users } = res.data;
      users = users == null ? [] : users;
      commit('setGrowth', { users: users, key: Math.floor(Math.random() * 100) });
    });
  },
};

const getters = {
  age(state) {
    return state.age;
  },
  gender(state) {
    return state.gender;
  },
  sign_in_method(state) {
    return state.sign_in_method;
  },
  relationship(state) {
    return state.relationship;
  },
  growth(state) {
    return state.growth;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
