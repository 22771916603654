export const getMonthBranchResult = [
  {
    name: '天德貴人 Heavenly Virtue',
    detail:
      'This indicates that one will be privileged enough to enjoy a relatively smooth-sailing life, with minimal hiccup or misfortunes',
  },
  {
    name: '月德貴人 Monthly Virtue',
    detail:
      'This indicates that one will be privileged enough to enjoy a relatively smooth-sailing life, with minimal hiccup or misfortunes',
  },
  { name: '月空 Month Emptiness ', detail: '-' },
  { name: '五富 Five Prosperity ', detail: '-' },
  { name: '天財 Heavenly Wealth', detail: '' },
  { name: '臨日 Thriving Day', detail: '-' },
]

export const elementResult = ['坐煞	Sitting Sha', '向煞	Facing Sha']

export const getYearBranchResult = [
  {
    name: '男命破月 เดือนร้ายชาย',
    detail:
      'ผู้ชายที่มีเดือนร้ายก้ำกรายอยู่ ร้ายจะอยู่ข้างพ่อแม่ของเมียได้ ให้เวียนหัวมีแต่ทุกข์ไม่สุขใจ  รีบแก้ไขทรัพย์จึงอยู่คู่หากิน ผู้ชายมีเดือนร้ายในชะตา  ร้ายจะพาให้ทางเมียเสียทรัพย์สิน เสียจนหมดต้องร่ำไห้เป็นอาจินต์  จะสูญสิ้นถึงได้มาก็หมดไป',
  },
  {
    name: '女命破月 เดือนร้ายหญิง',
    detail:
      'แม้นแต่งงานกับหญิงที่มีเดือนร้าย รวยจะกลายยากจนข้นแค้นแสน เคราะห์ร้ายมากดีน้อยคนดูแคลน มิมิตรแม้นแต่ต้องถามน่ำเอี๊ยงดู หญิงที่มีเดือนร้ายร้ายจะสู่ ให้ทั้งคู่พ่อแม่ผัวหมดสุขได้ ทรัพย์สินที่เคยมีหนีหมดไป ถึงขั้นให้ถือกะลามาขอทาน',
  },
  {
    name: '鐵掃帚出 ไม้กวาดออก',
    detail:
      'ผู้หญิงมีไม้กวาดในชีวิต ดวงลิขิตเป็นเรื่องใหญ่อย่ากังขา ถึงมีกินมีใช้ในชะตา ก็จะพาให้ทุกข์หมดสุขใจ ไม่มีลาภวาสนาชะตาตก ในทรวงอกกลัดกลุ้มให้หมองไหม้ รีบจำกัดมารไม้กวาดสูญสิ้นไป ก็ทำให้ทรัพย์สินอยู่คู่กับตน',
  },
  {
    name: '鐵掃帚入 ไม้กวาดเข้า',
    detail:
      'ชะตานี้มีไม้กวาดมาตามติด สุดจะคิดเงินทองหมดสิ้นได้ ถึงจะหาได้มาก็หมดไป ต้องแก้ไขไม้กวาดนี้ชี้ชะตา ปีศาจร้ายคอยตามรังครวญอยู่  เป็นศัตรูทรัพย์สินที่หามา แม้นเส้นไหว้กำจัดไปในชะตา ก็จะพาให้ร่ำรวยช่วยเจริญ',
  },
  {
    name: '咸池桃花 มารดอกท้อ',
    detail:
      'ผู้ชายมีมารต้นหญ้าพายากจน ผู้หญิงหากมีดอกท้อชั่วเหลือหลาย มารต้นหญ้าถ้ามาแก้ก็จะกลาย  สิ่งที่ร้ายเปลี่ยนเป็นดีมีสุขใจ แม้นดอกท้อในกำแพงผัวรับเคราะห์  จะให้เหมาะนอกกำแพงเพิ่มรักใคร่ ชายหรือหญิงแรกก็ดีหลังมีภัย  รวยเท่าใดก็ไม่พ้นต้องจนลง',
  },
  {
    name: '芒神愁心 มารต้นหญ้า',
    detail:
      'มารต้นหญ้าตามมาชะตาร้าย รักจะกลายให้ชังเหมือนดังว่า แม้นเป็นชายหญิงคนรักไม่นำพา หญิงชะตาก็ทำให้ชายรวนเร มีแต่โรคมีแต่ทุกข์ไม่สุขจิต สุดจะคิดคู่ทุกข์ยากก็หันเห เงินก็สิ้นเกียรติก็หายเหลือคะเน รีบถ่ายเทกำจัดมารให้ผ่านไป',
  },
  {
    name: '孤辰無合 มารตัวหมัด',
    detail:
      'ดวงชะตาพามาสู่มารตัวหมัด สารพัดลำบากยากจนแสน ญาติพี่น้องเบือนหน้าพาดูแคลน ไปต่างแดนหวังสอบไล่ไม่ได้ดี น่าสงสารมารตัวหมัดทำลายหมด ข้าวปลาอดพึ่งใครเขาก็หนี ต้องทุกข์ยากเที่ยวเร่ร่อนดวงชีวี  จะให้ดีสร้างบุญไว้แก้ภัยตน',
  },
]

export const zodiacResult = [
  { name: '龍德 Dragon Virtue', detail: '-' },
  { name: '金匱 Golden Chest', detail: '-' },
  { name: '紅鸞 Red Matchmaker', detail: '-' },
  { name: '天狗 Sky Dog ', detail: '-' },
  { name: '勾絞 Hook ', detail: '-' },
  { name: '歲破 Year Breaker', detail: '-' },
  { name: '大耗 Greater Consumer', detail: '-' },
  { name: '五鬼 Five Ghost', detail: '-' },
  { name: '桃花 Peach Blossom', detail: '-' },
  { name: '血刃 Blood knife', detail: '-' },
  { name: '天罡 Heavenly Dipper', detail: '-' },
  { name: '歲刑 Year Punishment', detail: '-' },
]

export const zodiacResult2 = [
  { name: '坐煞 Sitting Sha', detail: '-' },
  { name: '向煞 Facing Sha', detail: '-' },
  { name: '歲煞 Annual Sha', detail: '-' },
  { name: '災煞 Calamity Sha', detail: '-' },
  { name: '病符 Sickness Charm', detail: '-' },
  { name: '福德 Fortune Virtue', detail: '-' },
  { name: '白虎 White Tiger', detail: '-' },
  { name: '官符 Officer Charm', detail: '-' },
  { name: '太陰 Moon', detail: '-' },
  { name: '太陽 Sun', detail: '-' },
  { name: '太歳 Grand Duke', detail: '-' },
]
export const dayStemTableResult = [
  {
    name: '天乙貴人 Heavenly Nobleman',
    detail:
      "There will always be plenty of helpful people around to lend their support, especially in one's times of need. Furthermore, the person assisted by such noble people will benefit greatly in terms of fortune and luck.",
  },
  { name: '咸池 Salty Pool', detail: '-' },
  {
    name: '文昌 Intelligence',
    detail: `This indicates an intelligent, wise person; who will possess the uncanny wisdom to avoid any potential troubles in life.`,
  },
  { name: '祿神 Thriving', detail: '-' },
  {
    name: '羊刀 Goat Blade',
    detail:
      'This is a grim indicator of the occurrence of an accident, bodily harm or disaster that leads to a possible death in the family.',
  },
  {
    name: '紅艶 Red Chamber',
    detail:
      'This indicates someone who is open-minded and receptive enough to consider the opinions and suggestions of others. Indicates the possibility of infidelity.',
  },
  { name: '學堂 Study Hall', detail: '-' },
  { name: '流霞 Cascading Cloud', detail: '-' },
  { name: '飛刀 Flying Blade', detail: '-' },
  { name: '天財 Heavenly Wealth', detail: '-' },
  { name: '金輿 Gold Carriage', detail: '-' },
]

export const dayBranchResult = [
  {
    name: '華蓋 Elegant Seal',
    detail:
      "Those falling under the influence of this Star will be invariably talented in the fields of art and literature, although they may come across as being 'eccentric' to others.",
  },
  {
    name: '將星 The General Star / Obstruction Killing ',
    detail:
      "This indicates someone who excels in the literary and Those falling under the influence of this Star will be invariably talented in the fields of art and literature, although they may come across as being 'eccentric' to others.",
  },
  {
    name: '驛馬 Sky Horse',
    detail:
      'One who is influenced by the Sky Horse Star possesses superb initiative, and displays a tendency towards embarking on new endeavors.  However, the results of such endeavors could either range from good to better; or bad to worse.',
  },
  {
    name: '桃花 Peach Blossom',
    detail:
      "This Star 'cuts' two ways: If it is denominated by the month or year, it manifests itself as 'Interior Peach Blossom', which indicates a harmonious relationship between spouses.  If it manifests itself according to the generic factor of time, however, it becomes 'Exterior Peach Blossom', which spells misfortune and ill-luck.",
  },
  { name: '血刀 Blood Knife', detail: '-' },
  { name: '孤辰 Solitary', detail: '-' },
  { name: '寡宿 Lonesome', detail: '-' },
  { name: '喪門 Funeral Door', detail: '-' },
  {
    name: '劫煞 Robbery Sha',
    detail:
      "This Star is also known as 'Da Sha'(大煞),which literally translates as 'Great Killing, The presence of this Star indicates a person who is intelligent and capable enough of achieving great things in life, but not without encountering his/her fair amount of challenges along the way. This star also indicates loss of wealth and potential robberies.",
  },
  { name: '隔角 Seperating Edge', detail: '-' },
  {
    name: '亡神 Death God',
    detail:
      "This Star is also known as the 'Official Charm' Star(官符). In its negative form, this Star is the harbinger of lawsuits and misfortune for married women in the family. In its positive form, however, the energies of this Star augur well for strategic planning purposes.",
  },
]
