<template>
    <v-snackbar v-model="isAlert" top :color="alertType" timeout="5000">
        {{ message }}
        <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="closeAlert">
                Close
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    name: "alert",
    computed: {
        message() {
            return this.$store.getters.message
        },
        isAlert() {
            return this.$store.getters.isAlert
        },
        alertType() {
            return this.$store.getters.alertType
        }
    },
    methods: {
        closeAlert() {
            this.$store.dispatch('closeAlert')
        },
    },
};
</script>
