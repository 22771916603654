import { ghostFestivalCh } from '@/assets/calendar/edit-date-detail-form.js'
import {
  parseMonth,
  parseDate,
} from '@/assets/date-selection-calendar/date-parse.js'

import {
  breakerParse,
  noWealthDayParse,
  personalWealthParse,
  personalBreakerParse,
  combineAnimalParse,
  solitaryStarGroomParse,
  solitaryStarBrideParse,
  skyHappinessStarParse,
  yearProsperityParse,
  assetAcquireParse,
  heavenlyWealthStarParse,
  personalExtinctionStarParse,
  illnessStarParse,
  heavenlyDoctorStarParse,
  startingDietParse,
  personalNobleManParse,
  robberyShaParse,
  calamityShaParse,
  annualShaParse,
  heavenlyVirtueParse,
  monthlyVirtueParse,
  threeHarmonySetGroup,
  threeHarmonyDaySet,
} from '@/assets/date-selection-calendar/bazi.js'
import {
  breakerResult,
  noWealthDayResult,
  personalWealthResult,
  personalBreakerResult,
  combineAnimalResult,
  combineAnimalYearResult,
  combineAnimalMonthResult,
  solitaryStarGroomResult,
  solitaryStarBrideResult,
  skyHappinessStarResult,
  yearProsperityResult,
  assetAcquireResult,
  heavenlyWealthStarResult,
  personalExtinctionStarResult,
  illnessStarResult,
  heavenlyDoctorStarResult,
  startingDietResult,
  personalNobleManResult,
  annualShaResult,
  robberyShaResult,
  calamityShaResult,
  heavenlyVirtueNobleResult,
  monthlyVirtueNobleResult,
  threeHarmonyResult,
  monthCrashResult,
} from '@/assets/date-selection-calendar/bazi-result.js'

export function findMostGhost(month) {
  let ghostArr = month.map((i) => {
    return i.ghost_festival_year_ch
  })
  return mode(ghostArr)
}

export function isDemonDay(day_ch) {
  let badDay = [3, 7, 13, 18, 22, 27]
  return badDay.includes(day_ch) ? 'วันอสูรร้าย' : null
}

export function isDemonMonth(day_ch, month_ch) {
  if (month_ch == 7 || month_ch == 8) {
    if (day_ch <= 30) return 'ช่วงหลีกเลี่ยง'
  }
  if (month_ch == 9) {
    if (day_ch <= 9) return 'ช่วงหลีกเลี่ยง'
  }
  return null
}

export function findGhostInterval(months, ghost) {
  let interval = months.filter((i) => {
    return i.ghost_festival_year_ch == ghost
  })
  return [interval[0].date, interval[interval.length - 1].date]
}

export function getDateCh(day_ch, month_ch) {
  let date_ch = ''
  // Show month only first day of the month in calendar
  if (day_ch == 1) {
    if (month_ch == '2-2' || month_ch < 0 || month_ch == 22 || month_ch > 12) {
      date_ch = `${parseMonth[13]}${parseMonth[2]}${parseDate[day_ch]}`
    } else {
      date_ch = `${parseMonth[month_ch]}${parseDate[day_ch]}`
    }
  } else {
    date_ch = `${parseDate[day_ch]}`
  }
  return date_ch
}
// helper function
export function getDateFull(date) {
  const f = new Intl.DateTimeFormat('th', { month: 'long', day: 'numeric' })
  return f.format(new Date(date))
}

export function getTomorrowDate(date) {
  let tomorrow = new Date(date)
  tomorrow.setDate(tomorrow.getDate() + 1)
  return tomorrow.toISOString().slice(0, 10)
}
export function getYesterdayDate(date) {
  let yesterday = new Date(date)
  yesterday.setDate(yesterday.getDate() - 1)
  return yesterday.toISOString().slice(0, 10)
}
// Find If should find Four Separating / Four Extinction
export function isGhostChangeTomorrow(month, tomorrowDate, todayGhost) {
  let day = month.filter((d) => d.date == tomorrowDate)[0]
  if (typeof day == 'undefined') {
    return false
  }
  let tomorrowGhost = day?.ghost_festival_year_ch || 0
  return todayGhost !== tomorrowGhost
}

export function isChangeGhostYesterday(month, yesterdayDate, todayGhost) {
  let day = month.filter((d) => d.date == yesterdayDate)[0]
  if (typeof day == 'undefined') {
    return false
  }
  let yesterdayGhost = day?.ghost_festival_year_ch || 0
  return todayGhost !== yesterdayGhost
}

export function getTomorrowGhost(month, date) {
  let day = month.filter((d) => d.date == date)[0]
  let tomorrowGhost = day.ghost_festival_year_ch
  return tomorrowGhost
}

export function getPigDay(dayAnimal) {
  return dayAnimal == 12 ? 'วันหมู' : null
}

export function getAvoidGhost(ghost) {
  let avoidGhost = [4, 5, 6]
  return avoidGhost.includes(ghost) ? 'สารทหลีกเลี่ยง' : null
}

export function getFourSeparatingDay(tomorrowGhost) {
  let fourSeparating = [4, 10, 16, 22]
  return fourSeparating.includes(tomorrowGhost) ? 'Four Separating Days' : null
}
export function getFourExtinctDay(tomorrowGhost) {
  let fourExtinct = [1, 7, 13, 19]
  return fourExtinct.includes(tomorrowGhost) ? 'Four Extinct Days' : null
}

export function getBreaker(yearAnimal, dayAnimal, type) {
  return breakerParse[yearAnimal] == dayAnimal ? breakerResult(type) : null
}
export function getRobberySha(yearAnimal, dayAnimal) {
  return robberyShaParse[yearAnimal] == dayAnimal ? robberyShaResult : null
}
export function getCalamitySha(yearAnimal, dayAnimal) {
  return calamityShaParse[yearAnimal] == dayAnimal ? calamityShaResult : null
}
export function getAnnualSha(yearAnimal, dayAnimal) {
  return annualShaParse[yearAnimal] == dayAnimal ? annualShaResult : null
}
export function getNoWealthDay(yearPattern, dayPattern) {
  return noWealthDayParse[yearPattern].includes(dayPattern)
    ? noWealthDayResult
    : null
}
export function getPersonalWealth(yearPersonAnimal, dayAnimal) {
  return personalWealthParse[yearPersonAnimal] == dayAnimal
    ? personalWealthResult
    : null
}
export function getPersonalBreaker(yearPersonAnimal, dayAnimal) {
  return personalBreakerParse[yearPersonAnimal] == dayAnimal
    ? personalBreakerResult
    : null
}
export function getCombineAnimal(yearPersonAnimal, dayAnimal) {
  return combineAnimalParse[yearPersonAnimal] == dayAnimal
    ? combineAnimalResult
    : null
}
export function getYearCombineAnimal(yearAnimal, dayAnimal) {
  return combineAnimalParse[yearAnimal] == dayAnimal
    ? combineAnimalYearResult
    : null
}
export function getMonthCombineAnimal(monthAnimal, dayAnimal) {
  return combineAnimalParse[monthAnimal] == dayAnimal
    ? combineAnimalMonthResult
    : null
}
export function getSolitaryStarGroom(groomYearAnimal, dayAnimal) {
  return solitaryStarGroomParse[groomYearAnimal] == dayAnimal
    ? solitaryStarGroomResult
    : null
}
export function getSolitaryStarBride(brideYearAnimal, dayAnimal) {
  return solitaryStarBrideParse[brideYearAnimal] == dayAnimal
    ? solitaryStarBrideResult
    : null
}
export function getSkyHappinessStar(monthAnimal, dayAnimal) {
  return skyHappinessStarParse[monthAnimal] == dayAnimal
    ? skyHappinessStarResult
    : null
}
export function getYearProsperity(yearHeavenlyStem, dayAnimal) {
  return yearProsperityParse[yearHeavenlyStem] == dayAnimal
    ? yearProsperityResult
    : null
}
export function getAssetAcquire(yearHeavenlyStem, dayAnimal) {
  return assetAcquireParse[yearHeavenlyStem].includes(dayAnimal)
    ? assetAcquireResult
    : null
}
export function getHeavenlyWealthStar(personDayHeavenlyStem, dayHeavenlyStem) {
  return heavenlyWealthStarParse[personDayHeavenlyStem] == dayHeavenlyStem
    ? heavenlyWealthStarResult
    : null
}
export function getPersonalExtinctionStar(personDayHeavenlyStem, dayAnimal) {
  return personalExtinctionStarParse[personDayHeavenlyStem] == dayAnimal
    ? personalExtinctionStarResult
    : null
}
export function getIllnessStar(yearAnimal, dayAnimal) {
  return illnessStarParse[yearAnimal] == dayAnimal ? illnessStarResult : null
}
export function getHeavenlyDoctorStar(personMonthAnimal, dayAnimal) {
  return heavenlyDoctorStarParse[personMonthAnimal] == dayAnimal
    ? heavenlyDoctorStarResult
    : null
}
export function getStartingDiet(personYearAnimal, dayAnimal) {
  return startingDietParse[personYearAnimal] == dayAnimal
    ? startingDietResult
    : null
}
export function getPersonalNobleMan(personDayHeavenlyStem, dayAnimal) {
  return personalNobleManParse[personDayHeavenlyStem].includes(dayAnimal)
    ? personalNobleManResult
    : null
}
export function getHeavenlyVirtue(monthAnimal, dayHeaven, dayAnimal) {
  if (
    heavenlyVirtueParse[monthAnimal] == dayHeaven ||
    heavenlyVirtueParse[monthAnimal] == dayAnimal
  )
    return heavenlyVirtueNobleResult
  return null
}
export function getMonthlyVirtue(monthAnimal, dayHeaven) {
  return monthlyVirtueParse[monthAnimal] == dayHeaven
    ? monthlyVirtueNobleResult
    : null
}
export function getThreeHarmony(
  dayAnimal,
  yearAnimalPersonOne,
  yearAnimalPersonTwo
) {
  let baseSet = threeHarmonyDaySet[dayAnimal]
  if (baseSet == 0) return null
  let setHarmony = [dayAnimal, yearAnimalPersonOne, yearAnimalPersonTwo].sort(
    (a, b) => a - b
  )
  if (areEqual(setHarmony, threeHarmonySetGroup[baseSet])) {
    return threeHarmonyResult
  }
  return null
}

export function getMonthCrash(personMonthAnimal, monthAnimal) {
  return breakerParse[personMonthAnimal] == monthAnimal
    ? monthCrashResult
    : null
}

function areEqual(array1, array2) {
  if (array1.length === array2.length) {
    return array1.every((element, index) => {
      if (element === array2[index]) {
        return true
      }

      return false
    })
  }

  return false
}

export function getChangeGhost(ghost) {
  return ghostFestivalCh[ghost - 1]
}

export function isEmpty(obj) {
  return typeof obj == 'undefined' || obj == '' || obj == null || obj == '-'
}

const redOfficer = [2, 5, 8, 9, 11]
const redConstellation = [1, 4, 6, 7, 8, 13, 14, 16, 17, 19, 21, 22, 26, 28]

export function getRedOfficer(r) {
  return redOfficer.includes(r)
}
export function getRedContellation(r) {
  return redConstellation.includes(r)
}

function mode(arr) {
  return arr
    .sort(
      (a, b) =>
        arr.filter((v) => v === a).length - arr.filter((v) => v === b).length
    )
    .pop()
}
