// enum map
const requestType = {
  "Marriage": 1,
  "New-Car": 2,
  "Birth": 3,
  "Building-Construction": 4,
  "House-Construction": 5,
  "House-Moving": 6,
  "Contract-Signing": 7,
  "Business-Opening": 8,
  "Surgery": 9,
  "Treatment": 10,
  "Cosmetic-Surgery": 11,
  "Live-grave-or-Sheng-Ji": 12,
  "Chinese-Burial-Rituals": 13,
  "Repair": 14,
};

export default requestType;
