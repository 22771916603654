const auspiciousDateSchema = {
  date: '',
  start_time: '',
  end_time: '',
};

export const CandidateRequestSchema = {
  marriage: {
    candidate_dates: [candidateSchema],
  },
};

export const candidateSchema = {
  engagement: auspiciousDateSchema,
  arrival: auspiciousDateSchema,
};
